import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Api } from '../../contracts/classes/api.class';
import { ProgramLegacy } from '../../contracts/models/program.model';
import { Observable } from 'rxjs';
import { ProgramPlatform } from '../../contracts/models/program-platform';
import { Program } from '../../contracts/models/programa.model';

/** 
 * @version API.v1 
 * @deprecated
 */
interface GetAllProgramLegacyApiResult {
  ready: true;
  programas: ProgramLegacy[];
}

@Injectable({ providedIn: 'root' })
export class ProgramService extends Api {

  getAll(id: number): Observable<ProgramLegacy[]> {
    return this.http.get<GetAllProgramLegacyApiResult>(`${this.url}/programas/data/getListByMedio/${id}`)
    .pipe(map(result => result.programas));
  }

  getId(id: number) {
    return this.http.get(`${this.url}/programas/${id}`).pipe(
      map((response: any) => {
        return response.programa;
      })
    );
  }

  getListByMedio(id: number) {
    return this.http.get(`${this.url}/programas/data/getListByMedio/${id}`);
  }

  create(data: Program) {
    return this.http.post(`${this.url}/programas`, data);
  }

  update(data: Program) {
    return this.http.put(`${this.url}/programas/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/programas/${id}`);
  }

  getAllProgramaPlataformas(id: number) {
    return this.http.get(`${this.url}/programaPlataformas/data/getListByPrograma/${id}`);
  }
  getAllProgramaPlataformasSelect(idPrograma: number, idContact: number) {
    return this.http.get(`${this.url}/programaPlataformas/plataforma-select-by-program-id/${idPrograma}/${idContact}`);
  }

  createProgramaPlataformas(data: ProgramPlatform) {
    return this.http.post(`${this.url}/programaPlataformas`, data);
  }

  updateProgramaPlataformas(data: ProgramPlatform) {
    return this.http.put(`${this.url}/programaPlataformas/${data.id}`, data);
  }

  deleteProgramaPlataformas(id: number) {
    return this.http.delete(`${this.url}/programaPlataformas/${id}`);
  }
}
