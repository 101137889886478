<div>
    <div *ngIf="type === 'edit'" class="px-4 my-4 flex flex-row flex-wrap justify-between items-center content-center">
        <h3 class="m-0">Contacto</h3>
        <button type="button" mat-raised-button *ngIf="addPermission" color="primary" (click)="openDialog()">
            Agregar contacto
        </button>
    </div>

    <mat-form-field class="w-full">
        <input matInput placeholder="Buscar..." autocomplete="off" [formControl]="searchForm">
    </mat-form-field>
    <ngx-datatable class="material min" [messages]="messages" [loadingIndicator]="loading" [columnMode]="'force'" [headerHeight]="45"
        [footerHeight]="45" [rowHeight]="getRowHeight" [scrollbarV]="true" [scrollbarH]="true"
        [rows]="(list$ |async) | search: searchForm.value:keys ">
        
        <ngx-datatable-column name="#" [width]="10" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Contacto" prop="contacto">
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{value.nombres}} {{value.apellidos}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Programa / Sección" prop="programa.nombre">
        </ngx-datatable-column>
        <ngx-datatable-column name="Influencia" prop="tipoInfluencia">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngIf="value === 1">Externo</div>
                <div *ngIf="value === 2">Interno</div>
                <div></div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Cargo" prop="cargos">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngFor="let item of value">{{item.descripcion}}</div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Plataforma" prop="medioPlataformas">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngFor="let item of value">
                    {{item.plataforma_clasificacion.plataforma.descripcion}} >
                    {{item.plataforma_clasificacion.descripcion}}: {{item.valor}}
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Observación" prop="observacion">
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="type === 'edit'" name="Acciones" prop="id" [width]="160" [canAutoResize]="false" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button [disabled]="row.contacto.tiposPersona === '2' || row.activo === 0"
                    *ngIf="editPermission" mat-stroked-button mat-button-sm color="primary"
                    type="button" class="me-2" (click)="edit(row)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button [disabled]="row.contacto.tiposPersona === '2' || row.activo === 0"
                    *ngIf="deletePermission" mat-stroked-button mat-button-sm 
                    type="button" class="me-2" (click)="delete(value)">
                    <mat-icon>delete</mat-icon>
                </button>
                <button mat-stroked-button mat-button-sm [color]="row.activo === 0?'primary':'warn'"
                    [matTooltip]="row.activo === 1?'Desactivar':'Activar'" [disabled]="sending === row.id"
                    *ngIf="togglePermission" type="button" (click)="desactivate(row)">
                    <mat-icon class="relative -left-[6px]" iconPositionEnd [class.animate-spin]="sending === row.i" *ngIf="sending === row.id;else noSend">
                        autorenew
                    </mat-icon> 
                    <ng-template #noSend>
                        <mat-icon class="relative -left-[6px]">power_settings_new</mat-icon>
                    </ng-template>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>