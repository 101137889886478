import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { environment as env } from 'src/environments/environment';

export class Api {

    /** @description Servicio para las peticiones HTTP */
    protected http: HttpClient = inject(HttpClient);

    /** @description URI de la API Backend */
    protected source = env.app_urls.source;

    protected url = `${this.source}/api`

    protected urlv2 = `${this.source}/api/v2`

    protected prefix = () => '';

    protected link(path: string): string {
        return `${this.urlv2}/${this.prefix()}/${path}`
    }
}