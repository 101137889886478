import { Injectable } from '@angular/core';
import { Api } from '../../contracts/classes/api.class';
import { ProgramaContactos } from '../../contracts/models/contact-program.interface';

@Injectable({ providedIn: 'root' })
export class ProgramaContactosService extends Api {

  getListByMedio(id: number) {
    return this.http.get(`${this.url}/programaContactos/data/getListByMedio/${id}`);
  }

  getListByContacto(id: number) {
    return this.http.get(`${this.url}/programaContactos/data/getListByContacto/${id}`);
  }

  getListByContactoSelect(id: number) {
    return this.http.get(`${this.url}/programaContactos/program-select-by-contact-id/${id}`);
  }

  getId(id: number) {
    return this.http.get(`${this.url}/programaContactos/${id}`);
  }

  create(data: ProgramaContactos) {
    return this.http.post(`${this.url}/programaContactos`, data);
  }

  update(data: ProgramaContactos) {
    return this.http.put(`${this.url}/programaContactos/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/programaContactos/${id}`);
  }

  active(id: number) {
    return this.http.get(`${this.url}/programaContactos/data/activate/${id}`);
  }

  desactive(id: number) {
    return this.http.get(`${this.url}/programaContactos/data/deactivate/${id}`);
  }
}
