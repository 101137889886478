import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClassificationService } from 'src/app/core/services/api/classification.service';
import { PlatformService } from 'src/app/core/services/api/platform.service';

import { Observable, Subscription } from 'rxjs';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { PlatformMedia } from 'src/app/core/contracts/models/medio-plataformas';

@Component({
    selector: 'app-asignar-plataformas-form',
    templateUrl: './asignar-plataformas-form.component.html',
    styleUrls: ['./asignar-plataformas-form.component.scss']
})
export class AsignarPlataformasFormComponent implements OnInit {
    public loading: boolean = false;

    myForm = this.formBuilder.group({
        platform: new FormControl<number|null>(null,[Validators.required]),
        classification: new FormControl<number|null>(null,[Validators.required]),
        url:  new FormControl<string|null>(null), 
        scope: new FormControl<number|null>(null),
        valor:  new FormControl<number|null>(null),
        observation: new FormControl<string|null>(null)
    });

    platform: PlatformMedia = {
        id: null,
        medio_id: null,
        idPlataformaClasificacion: null,
        valor: null,
        alcance: null,
        observacion: null,
        valorizacion: null
    }

    platforms: any[] = [];
    classificationsTemp: any[] = [];
    classifications: any[] = [];

    title = 'Agregar plataforma';

    subscriptionForm: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AsignarPlataformasFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { row: PlatformMedia, id: number },
        private platformService: PlatformService,
        private classificationService: ClassificationService,
        private swal: Swal2
    ) {
        if (data.row) {
            this.title = 'Editar plataforma';
        }
    }

    ngOnInit(): void {
        this.platformService.getAll().subscribe((response: any) => {
            const { plataformas } = response;
            this.platforms = plataformas;
        });

        this.classificationService.getAll().subscribe(response => {
            const { plataformaClasificacions } = response;
            this.classificationsTemp = plataformaClasificacions;

            if (this.data.row) {
                this.loadForm();
            }
        });

        this.subscriptionForm = this.myForm.controls.platform.valueChanges.subscribe(id => {
            if (id) {
                this.classifications = this.classificationsTemp.filter(c => c.plataforma_id === id);
            } else {
                this.classifications = [];
                this.myForm.controls.classification.setValue(null);
            }
        });

    }

    loadForm() {
        this.myForm.controls.platform.setValue(this.data.row.plataforma_clasificacion.plataforma.id);
        this.myForm.controls.classification.disable();
        this.myForm.controls.classification.setValue(this.data.row.plataforma_clasificacion.id);
        this.myForm.controls.classification.disable();
        this.myForm.controls.url.setValue(this.data.row.valor);
        this.myForm.controls.scope.setValue(this.data.row.alcance);
        this.myForm.controls.observation.setValue(this.data.row.observacion);
        // Añadir esta línea para cargar el valor de valorización
        this.myForm.controls.valor.setValue(Number(this.data.row.valorizacion_plataforma));
    }

    ngOnDestroy() {
        this.subscriptionForm.unsubscribe();
    }

    onSubmit() {
        this.platform={
            medio_id : this.data.id,
            alcance:this.myForm.controls.scope.value,
            idPlataformaClasificacion:this.myForm.controls.classification.value,
            valor:this.myForm.controls.url.value,
            observacion:this.myForm.controls.observation.value,
            valorizacion:this.myForm.controls.valor.value,
        }
        let observable: Observable<any> | undefined = undefined;
        if (this.data.row) {
            this.platform.id = this.data.row.id;
            observable = this.platformService.updateMedioPlataformas(this.platform)
        } else {
            observable = this.platformService.createMedioPlataformas(this.platform)
        }
        this.loading = true;
        observable.subscribe((response: any) => {
            this.dialogRef.close(response);
            this.loading = false;
            this.swal.showToast(response.message, 'success');
        }, error => {
            if (error.status === 400) {
                this.error(error);
            }
            this.loading = false    

        });
    }

    error(error) {
        if (error.error.errors.valor) {
            this.swal.showToast(error.error.errors.valor[0], 'error');
        }
    }

}