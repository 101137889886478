<div mat-dialog-title class="!text-base">
    Valorar Campaña: {{data.titulo}}
    <!--mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar-->
</div>

<div mat-dialog-content class="py-0">
    <app-campaign-labels [campaign]="campaign" [words]="true" [label]="'Etiquetas'" (outLabel)="getLabels($event)">
    </app-campaign-labels>
    <app-rate-campaign [data]="campaign" (onRate)="onRate($event)"></app-rate-campaign>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" type="button" mat-dialog-close
        class="me-4">Cancelar</button>
    
        <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="rateForm.invalid" [loading]="buttonSubmit"
        label="Guardar"></adp-button-icon>
</div>