import {Component, Inject, OnInit} from '@angular/core';
import { Validators, UntypedFormBuilder, FormArray, FormGroup, FormControl } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {SpokesmenService} from 'src/app/core/services/api/spokesmen.service';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import {PeopleService} from '../../core/services/api/people.service';
import {DynamicSelectService} from '../../core/services/api/dynamic-select.service';
import {Option} from '../../core/contracts/models/option';
import {CategoryService} from '../../core/services/api/category.service';
import {TemasService} from '../../core/services/api/temas.service';
import {Category} from '../../core/contracts/models/category';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { Phone } from 'src/app/core/contracts/models/phone';
import { Email } from 'src/app/core/contracts/models/email';
import { Person } from 'src/app/core/contracts/models/person.model';



interface PhoneForm {
    idTipoTelefono: FormControl<number>;
    telefono: FormControl<string>;
}

interface EmailForm {
    idTipoEmail: FormControl<number>;
    email: FormControl<string>;
}

@Component({
    selector: 'app-new-person',
    templateUrl: './new-person.component.html',
    styleUrls: ['./new-person.component.scss']
})
export class NewPersonComponent implements OnInit {
    public loading:boolean = false;

    emailsForm: FormArray<FormGroup<EmailForm>> = this.formBuilder.array([]);

    phonesForm: FormArray<FormGroup<PhoneForm>> = this.formBuilder.array([]);

    myForm = this.formBuilder.group({
        name: [null, Validators.required],
        lastName: [null,Validators.required],
        sex: [null],
        vinculo: [null],
        customer: [null],
        cargo: [null],
        tipos: [[]],
        categories: [[]],
        temas: [[]],
        filterC: [null]
    });

    typeEmails: Option[] = [];
    typePhones: Option[] = [];

    title = 'Crear Persona';
    buttonSubmit = false;
    formSubmitAttempt = false;

    personEdit: Person;

    categories: Category[] = [];
    temas: any[] = [];
    selectCat$: Observable<any>;

    contacto = false;
    vocero = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        public dialogRef: MatDialogRef<NewPersonComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { tipo: any, idCliente: number, edit?: boolean, person?: Person },
        private spokesmenService: SpokesmenService,
        private swal: Swal2,
        private peopleService: PeopleService,
        private dynamicSelectService: DynamicSelectService,
        private temasService: TemasService,
        private categoryService: CategoryService
    ) {
    }

    ngOnInit(): void {
        this.dynamicSelectService.getListBySlug('email').subscribe((response: any) => {
            this.typeEmails = response.tipoAtributos;
        });

        this.dynamicSelectService.getListBySlug('telephone').subscribe((response: any) => {
            this.typePhones = response.tipoAtributos;
        });

        if (this.data.tipo.includes(1)) {
            this.temasService.getAll().subscribe((response: any) => {
                const {temas} = response;
                this.temas = temas;
            });

            this.categoryService.legacyGetAll().subscribe((response: any) => {
                const {categorias} = response;
                this.categories = categorias;
            });
            this.contacto = true;
        }

        this.selectCat$ = this.myForm.get('categories').valueChanges.pipe(
            map(res => {
                return res ? this.categories.filter(ct => res.includes(ct.id)) : []
            })
        )

        this.myForm.get('tipos').setValue(this.data.tipo)
        if (this.data.idCliente) {
            this.myForm.get('cargo').setValidators([Validators.required]);
        }

        if (this.data.edit) {
            this.title = 'Editar contacto a vocero';
            this.peopleService.getId(this.data.person.id).subscribe((response: Person) => {
                const tipos = response.tiposPersona ? response.tiposPersona.split(',').map(item => Number(item)) : [];
                tipos.push(2);
                this.personEdit = {
                    id: this.data.person.id,
                    nombres: response.nombres,
                    apellidos: response.apellidos,
                    genero: response.genero,
                    apodo: response.apodo,
                    fechaNacimiento: response.fechaNacimiento,
                    observacion: response.observacion,
                    profesion: response.profesion,
                    emails: response.emails,
                    telefonos: response.telefonos,
                    direccions: response.direccions,
                    reds: response.reds,
                    famoso: response.famoso,
                    tiposPersona: tipos,
                    categorias: response.categorias,
                    horarios: response.horarios,
                    temas: response.temas
                }
                response.emails.forEach(em => {
                    this.emailsForm.push(
                        this.formBuilder.group({
                            idTipoEmail: [em.idTipoEmail, Validators.required],
                            email: [em.email, [Validators.required, Validators.email]]
                        })
                    )
                })
                response.telefonos.forEach(ph => {
                    this.phonesForm.push(
                        this.formBuilder.group({
                            idTipoTelefono: [ph.idTipoTelefono, Validators.required],
                            telefono: [ph.telefono, [Validators.required, Validators.maxLength(12), Validators.pattern(/\(?(^[0-9-+\s()#]*$)/)]]
                        })
                    )
                })
                this.myForm.get('name').setValue(response.nombres);
                this.myForm.get('name').disable()
                this.myForm.get('lastName').setValue(response.apellidos);
                this.myForm.get('lastName').disable()
                this.myForm.get('sex').setValue(response.genero);
                this.myForm.get('sex').disable()
            });
        }
        if (!this.data.edit && this.data.person) {
            this.vocero = true;
            this.title = 'Asignar vocero a cliente';
            this.myForm.get('name').setValue(this.data.person.nombres);
            this.myForm.get('name').disable();
            this.myForm.get('lastName').setValue(this.data.person.apellidos);
            this.myForm.get('lastName').disable();
            this.myForm.get('sex').setValue(this.data.person.genero);
            this.myForm.get('sex').disable();
        }
    }

    edit() {
        this.personEdit.telefonos = this.phonesForm.value as Phone[];
        this.personEdit.emails = this.emailsForm.value as Email[];
        this.peopleService.edit(this.personEdit).subscribe((response: any) => {
            this.spokesmen(this.personEdit);
        }, error => {
            if (error.status === 400) {
                this.errorsPersona(error);
            }
            //this.buttonSubmit = false;
            this.loading = false;
        });
    }

    spokesmen(newPerson) {
        let cliente = {
            cliente_id: this.data.idCliente,
            idVocero: newPerson.id,
            cargo: this.myForm.get('cargo').value
        }
        this.spokesmenService.createClienteVoceros(cliente).subscribe((response: any) => {
            //this.buttonSubmit = false;
            this.loading = false;
            this.swal.showToast(response.message, 'success');
            this.dialogRef.close(newPerson);
        }, error => {
            this.swal.showToast(error.error.message, 'error');
            //this.buttonSubmit = false;
            this.loading = false;
        });
    }

    create() {
        let person = {
            nombres: this.myForm.get('name').value,
            apellidos: this.myForm.get('lastName').value,
            genero: this.myForm.get('sex').value,
            tiposPersona: this.myForm.get('tipos').value || this.data.tipo,
            emails: this.emailsForm.value,
            telefonos: this.phonesForm.value,
            categorias: this.myForm.get('categories').value,
            temas: this.myForm.get('temas').value
        }

        this.peopleService.create(person as Person).subscribe((response: any) => {
            const newPerson = response.persona;
            if (this.data.idCliente) {
                this.spokesmen(newPerson);
            } else {
                //this.buttonSubmit = false;
                this.loading = false;
                this.swal.showToast(response.message, 'success');
                this.dialogRef.close(newPerson);
            }

        }, error => {
            if (error.status === 400) {
                this.errorsPersona(error);
            }
            //this.buttonSubmit = false;
            this.loading = false;
        });
    }

    onSubmit() {
        this.formSubmitAttempt = true;
        if (this.myForm.invalid || this.emailsForm.invalid || this.phonesForm.invalid) {
            this.myForm.markAllAsTouched();
            return;
        }
        //this.buttonSubmit = true;
        this.loading=true;
        if (!this.data.edit && !this.data.person) {
            this.create();
        }
        if (!this.data.edit && this.data.person) {
            this.spokesmen(this.data.person);
        }
        if (this.data.edit) {
            this.edit();
        }

    }

    isFieldValid(field: string): boolean {
        return this.myForm.get(field).invalid && this.formSubmitAttempt;
    }

    errorsPersona(error) {
        if (error.error.errors) {
            if (error.error.errors.nroDocumento) {
                this.swal.showToast(error.error.errors.nroDocumento[0], 'error');
            }
            if (error.error.errors.telefono) {
                this.swal.showToast(error.error.errors.telefono[0], 'error');
            }
            if (error.error.errors.email) {
                this.swal.showToast(error.error.errors.email[0], 'error');
            }
            if (error.error.errors.red) {
                this.swal.showToast(error.error.errors.red[0], 'error');
            }
        } else {
            this.swal.showToast(error.error.message, 'error');
        }
    }

    deletePhone(ind) {
        this.phonesForm.removeAt(ind);
    }

    deleteEmail(ind) {
        this.emailsForm.removeAt(ind);
    }

    addPhone() {
        this.phonesForm.push(
            this.formBuilder.group({
                idTipoTelefono: [null, Validators.required],
                telefono: [null, [Validators.required, Validators.maxLength(25), Validators.pattern(/\(?(^[0-9-+\s()#]*$)/)]]
            })
        )
    }

    addEmail() {
        this.emailsForm.push(
            this.formBuilder.group({
                idTipoEmail: [null, Validators.required],
                email: [null, [Validators.required, Validators.email]]
            })
        )
    }
}
