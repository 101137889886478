import {Component, OnInit, Inject} from '@angular/core';
import {Option} from 'app/core/contracts/models/option';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DynamicSelectService} from 'src/app/core/services/api/dynamic-select.service';
import { Email } from 'src/app/core/contracts/models/email';

@Component({
    selector: 'app-types-of-emails-form',
    templateUrl: './types-of-emails-form.component.html',
    styleUrls: ['./types-of-emails-form.component.scss']
})
export class TypesOfEmailsFormComponent implements OnInit {

    emails: Option[] = [];
    email: Email = {
        idTipoEmail: null,
        tipo_email: null,
        email: null
    };

    title = 'Agregar correo';
    buttonSubmit = true;

    constructor(
        public dialogRef: MatDialogRef<TypesOfEmailsFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Email,
        private dynamicSelectService: DynamicSelectService
    ) {
        if (data) {
            this.title = 'Editar correo';
            this.email.tipo_email = data.tipo_email;
            this.email.idTipoEmail = data.tipo_email.id;
            this.email.email = data.email;
        }
    }

    ngOnInit(): void {

        this.dynamicSelectService.getListBySlug('email').subscribe((response: any) => {
            this.emails = response.tipoAtributos;
        });
    }

    onSubmit() {
        this.email.tipo_email = this.emails.find(e => e.id === this.email.idTipoEmail);
        this.dialogRef.close({...this.email});
    }

    cancel() {
        this.dialogRef.close(this.data);
    }
}
