<div class="card-cont">
    <div class="flex flex-row justify-start items-start content-start">
        <div class="px-4">
            <ng-content select="[slot=check]"></ng-content>
        </div>
        <div class="px-4">
            <div class="contact">{{row.contact.nombres}} {{row.contact.apellidos}}</div>
            <div class="contact-details">
                <div class="detail">
                    <div class="detail__title">Estado</div>
                    <div>{{row.statusName}}</div>
                </div>
                <div class="detail">
                    <div class="detail__title"> <strong>Medio:</strong> programa</div>
                    <div>
                        {{row.media.programa.medio.nombre}}: <i>{{row.media.programa.nombre}}</i>
                    </div>
                </div>
                <div class="detail">
                    <div class="detail__title">Plataformas</div>
                    <div>
                        <div *ngFor="let p of row.platform">
                            {{p.plataforma_clasificacion.plataforma.descripcion}}
                            <i>{{p.plataforma_clasificacion.descripcion}}: {{p.valor}}</i>
                        </div>
                    </div>
                </div>
                <div class="detail">
                    <div class="detail__title">Tipo de nota</div>
                    <div>{{ row.typeNote.name}}</div>
                </div>
                <div class="detail">
                    <div class="detail__title">Estado de Muestra</div>
                    <div>{{row.statusMuestra}}</div>
                </div>
            </div>
            <div class="flex flex-row justify-end items-start content-start">
                <ng-content select="[slot=actions]"></ng-content>
            </div>

        </div>
    </div>
</div>
<hr>