import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AsignarProgramasFormComponent } from '../form/asignar-programas-form.component';
import { ProgramService } from '../../../core/services/api/program.service';
import { AsignarProgramasLinkComponent } from '../link/asignar-programas-link.component';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProgramLegacy } from 'src/app/core/contracts/models/program.model';

@Component({
    selector: 'app-asignar-programas-list',
    templateUrl: './asignar-programas-list.component.html',
    styleUrls: ['./asignar-programas-list.component.scss']
})
export class AsignarProgramasListComponent implements OnInit {

    @Input() id: number;
    rows: ProgramLegacy[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    searchForm = new FormControl<string>(null);
    keys = ['nombre', 'descripcion'];

    addPermission = this.auth.isPermit('medio.programa.add')
    editPermission = this.auth.isPermit('medio.programa.edit')
    deletePermission = this.auth.isPermit('medio.programa.delete')

    constructor(
        public dialog: MatDialog,
        private programService: ProgramService,
        private auth: AuthService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.programService.getAll(this.id).subscribe(result => {
            this.rows = result;
        });
    }

    add() {
        this.openDialog();
    }

    edit(row: any) {
        this.openDialog(row);
    }

    getRowHeight(row: ProgramLegacy) {
        return Math.max(50, Math.floor(row.programa_plataformas.length * 20) + 50);
    }

    openDialog(row?: any) {
        const dialogRef = this.dialog.open(AsignarProgramasFormComponent, {
            data: {row, id: Number(this.id)},
            width: '800px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAll();
            }
        });
    }

    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.programService.delete(id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }

    link(row) {
        const dialogRef = this.dialog.open(AsignarProgramasLinkComponent, {
            data: row,
            width: '850px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAll();
            }
        });
    }

    getIconName(medioPlatName: string, medioPlatClassName: string): string {
        switch (medioPlatName.toLowerCase()) {
          case 'web': return 'language';
          case 'televisión': return 'tv';
          case 'radio': return 'radio';
          case 'impreso': return 'menu_book';
          case 'social media':
            switch (medioPlatClassName.toLowerCase()) {
              case 'facebook': return 'fab fa-facebook-f';
              case 'youtube': return 'fab fa-youtube';
              case 'twitter': return 'fab fa-twitter';
              case 'instagram': return 'fab fa-instagram';
              case 'spotify': return 'fab fa-spotify';
              case 'linkedin': return 'fab fa-linkedin-in';
              case 'tiktok' : return 'fab fa-tiktok'
              default: return 'public';
            }
          case 'influencer':
            if (medioPlatClassName.toLowerCase().includes('stories')) {
              return 'fab fa-instagram';
            }
            return 'person';
          default: return 'public';
        }
      }
}