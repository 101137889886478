<div mat-dialog-title class="!flex justify-between items-center">
    <span class="!text-base">Contacto: {{data.contacto.nombres}} {{data.contacto.apellidos}}</span>
    <span class="flex-1"></span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <p>
        <mat-icon style="vertical-align: middle;">info</mat-icon>
        Al desactivar el contacto del medio, verificar los correos
    </p>
    <ngx-datatable class="material" [messages]="messages" [rows]="rows" [columnMode]="'force'" [headerHeight]="45"
        rowHeight="auto">
        <ngx-datatable-column name="#" [width]="10" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Tipo" [width]="80" prop="tipo_email.name"></ngx-datatable-column>
        <ngx-datatable-column name="Correo" prop="email"></ngx-datatable-column>
        <ngx-datatable-column name="Acciones" [width]="200" [canAutoResize]="false" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
                <button mat-stroked-button mat-button-sm type="button" matTooltip="Eliminar"
                    (click)="deleteEmail(row.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</mat-dialog-content>