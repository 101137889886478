<div class="flex flex-row flex-wrap justify-start items-center content-center">
    <mat-form-field class="w-full" [appearance]="apparience">
        <mat-label>{{label}}</mat-label>
        <mat-chip-grid #chipList>
            <mat-chip-row *ngFor="let et of etiquetas" [removable]="removable"
                (removed)="remove(et.slug)">
                {{et.slug}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip-row>
            <input 
                placeholder="Agregar etiqueta" autocomplete="off" #labelInput [formControl]="labelForm"
                [matAutocomplete]="autoLabel" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)"
            >
        </mat-chip-grid>
        <mat-autocomplete 
            #autoLabel="matAutocomplete" 
            autoActiveFirstOption 
            (optionSelected)="selected($event)"
            position="below">
             <mat-option *ngFor="let label of filteredLabels | async" [value]="label">
                {{label.slug}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
