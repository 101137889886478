import {Component, OnInit, Inject} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Campaign } from 'src/app/core/contracts/models/campaign.interface';

import {CampaignsService} from 'src/app/core/services/api/campaigns.service';
import { Swal2 } from 'src/app/core/utils/swal2.util';

@Component({
    selector: 'app-validate-modal',
    templateUrl: './validate-modal.component.html',
    styleUrls: ['./validate-modal.component.scss']
})
export class ValidateModalComponent implements OnInit {

    rateForm = this.formBuilder.group({
        tipoPublico:      [null, Validators.required],
        tipoObjetivo:     [null, Validators.required],
        tipoAudiencia:    [null, Validators.required],
        interesPublico:   [null, [Validators.required, Validators.min(1)]],
        novedad:          [0, [Validators.required, Validators.min(1)]],
        actualidad:       [0, [Validators.required, Validators.min(1)]],
        autoridadCliente: [0, [Validators.required, Validators.min(1)]],
        mediaticoCliente: [0, [Validators.required, Validators.min(1)]],
        autoridadVoceros: [0, [Validators.required, Validators.min(1)]],
        mediaticoVoceros: [0, [Validators.required, Validators.min(1)]]
    });

    buttonSubmit = false;
    dataRate: any;

    platforms = [];
    etiquetas: any = [];

    campaign: Campaign;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<ValidateModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Campaign,
        private campaignsService: CampaignsService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.campaign = this.data;
        this.dataRate = {
            tipoPublico:      this.data.tipoPublico,
            tipoObjetivo:     this.data.tipoObjetivo,
            tipoAudiencia:    this.data.tipoAudiencia,
            interesPublico:   this.data.interesPublico ? this.data.interesPublico : 0,
            novedad:          this.data.novedad ? this.data.novedad : 0,
            actualidad:       this.data.actualidad ? this.data.actualidad : 0,
            autoridadCliente: this.data.autoridadCliente ? this.data.autoridadCliente : 0,
            mediaticoCliente: this.data.mediaticoCliente ? this.data.mediaticoCliente : 0,
            autoridadVoceros: this.data.autoridadVoceros ? this.data.autoridadVoceros : 0,
            mediaticoVoceros: this.data.mediaticoVoceros ? this.data.mediaticoVoceros : 0
        }
        this.rateForm.setValue(this.dataRate);

        if (!this.data.etiquetas) {
            this.campaignsService.getId(this.data.id).subscribe((response: Campaign) => {

                this.campaign = response;
                this.etiquetas = response.etiquetas.map(et => et.slug);

                const dataRate = {
                    tipoPublico:      response.tipoPublico,
                    tipoObjetivo:     response.tipoObjetivo,
                    tipoAudiencia:    response.tipoAudiencia,
                    interesPublico:   response.interesPublico ? response.interesPublico : 0,
                    novedad:          response.novedad ? response.novedad : 0,
                    actualidad:       response.actualidad ? response.actualidad : 0,
                    autoridadCliente: response.autoridadCliente ? response.autoridadCliente : 0,
                    mediaticoCliente: response.mediaticoCliente ? response.mediaticoCliente : 0,
                    autoridadVoceros: response.autoridadVoceros ? response.autoridadVoceros : 0,
                    mediaticoVoceros: response.mediaticoVoceros ? response.mediaticoVoceros : 0
                }

                this.rateForm.setValue(dataRate);
            });
        }
    }

    getLabels(labels) {
        this.etiquetas = labels;
    }

    onRate(rate) {
        this.rateForm.setValue(rate);
        this.dataRate = rate;
    }

    onSubmit() {
        if (!this.etiquetas.length) {
            this.swal.showAlert('Agregue etiquetas', 'info');
            return;
        }
        this.buttonSubmit = true;
        const campaign = {
            campaign_id:      this.data.id,
            tipoPublico:      this.dataRate.tipoPublico,
            tipoObjetivo:     this.dataRate.tipoObjetivo,
            tipoAudiencia:    this.dataRate.tipoAudiencia,
            interesPublico:   this.dataRate.interesPublico,
            novedad:          this.dataRate.novedad,
            actualidad:       this.dataRate.actualidad,
            autoridadCliente: this.dataRate.autoridadCliente,
            mediaticoCliente: this.dataRate.mediaticoCliente,
            autoridadVoceros: this.dataRate.autoridadVoceros,
            mediaticoVoceros: this.dataRate.mediaticoVoceros,
            etiquetas:        this.etiquetas
        }

        this.campaignsService.saveValor(campaign).subscribe({
            next: (response: any) => {
                this.swal.showToast(response.message, 'success');
                this.buttonSubmit = false;
                this.dialogRef.close({
                    campaign: response['campaign']
                })
            }, 
            error: error => {
                if (error.status === 400) {
                    this.swal.showToast(error.error.message, 'error');
                }
                this.buttonSubmit = false;
            }
        });
    }

}
