import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { User } from 'src/app/core/contracts/models/user.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SIDEBAR_LOGO } from 'src/app/core/utils/constants';

const { version } = require('../../../../../../package.json');

@Component({
    selector: 'adp-admin-sidebar',
    templateUrl: './admin-sidebar.component.html',
})
export class AdminSidebarComponent implements OnInit, OnDestroy {
    
    private auth = inject(AuthService);

    public logo = SIDEBAR_LOGO;
    public user: User | undefined = undefined;
    public version: string = version;
    
    private userValueSubscription!: Subscription;

    ngOnInit(): void {
        this.userValueSubscription = this.auth.user$
        .pipe(filter(Boolean))
        .subscribe(user => this.user = user);
    }

    ngOnDestroy(): void {
        this.userValueSubscription.unsubscribe();
    }
}
