<mat-drawer-container [hasBackdrop]="true">
    <mat-drawer #notificationRightBar position="end" class="chat-panel" mode="over" [opened]="false">
        <app-sidenav-rigth (closeNotifications)="notificationRightBar.close()"></app-sidenav-rigth>
    </mat-drawer> 
    <mat-drawer-content class="!overflow-hidden">
        <mat-drawer-container class="min-h-screen max-h-screen h-screen w-screen relative" [hasBackdrop]="backdrop">

                <mat-drawer #drawer [mode]="mode" opened class="sidebar-panel">
                    <adp-admin-sidebar></adp-admin-sidebar>
                </mat-drawer>
                <mat-drawer-content class="flex flex-column bg-template">
                <app-header (toggleSidebarLinks)="drawer.toggle()" (showNotifications)="notificationRightBar.open()"></app-header>
                <main class="adp-height-container w-full p-4 overflow-y-auto scroll-smooth">
                  <router-outlet></router-outlet>
                </main>
                </mat-drawer-content>
            </mat-drawer-container>
    </mat-drawer-content>
</mat-drawer-container>


    


  
