import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface InternetConnecionDto {
    icon: string;
    message: string;
    description: string;
};

@Component({
    selector: 'app-internet-connection',
    templateUrl: './internet-connection.component.html',
    standalone: true,
    imports: [MatIconModule]
})
export class InternetConnectionComponent { 

    public snackBarRef: MatSnackBarRef<InternetConnectionComponent> = inject(MatSnackBarRef);

    public data:InternetConnecionDto = inject(MAT_SNACK_BAR_DATA);
}
