import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Select } from 'src/app/core/contracts/models/form-input.model';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '../contracts/models/api-response.model';

/**@version Apiv2.0 */
@Injectable({
  providedIn: 'root'
})
export class SearchBoxService {

  private url: string = `${environment.app_urls.source}/api/v2`;

  private diccionary = {
    contact: ( params: string ) => this.searchContactSelect( params ),
  }

  constructor( private http: HttpClient ) { }

  /**@version Apiv2.0 */
  public findObserver( method: string, params: string ): Observable<Select[]> {
    return this.diccionary[method]( params );
  }

  /**@version Apiv2.0 */
  private searchContactSelect( search: string ): Observable<Select[]> {
    const api_url = `${this.url}/personas/select/contact-list`;
    const params = new HttpParams().set('search', search )

    return this.http.get<ApiResponse>(api_url, { params })
    .pipe( 
        map( response => {
            if( response.data?.length) {
                return response.data.map(({id, label}) => ({id, label}));
            } else {
                return []
            }
        })
    );
  }
}
