<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form [formGroup]="myForm" >
        <div class="grid sm:grid-cols-2 gap-x-4 gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Nombre</mat-label>
                <input matInput type="text" autocomplete="off" formControlName="name" required>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Descripción</mat-label>
                <input matInput type="text" autocomplete="off" formControlName="description">
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Categorías</mat-label>
                <mat-select disableOptionCentering multiple formControlName="categories" placeholder="Seleccione">
                    <mat-select-trigger>
                        <mat-chip-listbox class="multi">
                            <mat-basic-chip class="chipName" *ngFor="let c of selectCategories$|async"
                                [removable]="true" (removed)="remove(c.id)">
                                <mat-icon matChipRemove>close</mat-icon>
                                <span>{{c.full_descripcion}}</span>
                            </mat-basic-chip>
                        </mat-chip-listbox>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search formControlName="filterC" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let c of categories| search: myForm.get('filterC').value:['full_descripcion']"
                        [value]="c.id">{{c.full_descripcion}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Periodicidad</mat-label>
                <mat-select disableOptionCentering multiple formControlName="periodicidad" placeholder="Seleccione">
                    <mat-option value="1">Lunes</mat-option>
                    <mat-option value="2">Martes</mat-option>
                    <mat-option value="3">Miércoles</mat-option>
                    <mat-option value="4">Jueves</mat-option>
                    <mat-option value="5">Viernes</mat-option>
                    <mat-option value="6">Sábado</mat-option>
                    <mat-option value="7">Domingo</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</div>
<div *ngIf="platforms.length">
    <div class="px-6">Plataformas</div>
    <ul>
        <li *ngFor="let p of platforms">
            <mat-checkbox [(ngModel)]="p.check" color="primary">
                {{p.plataforma_clasificacion.plataforma.descripcion}} >
                {{p.plataforma_clasificacion.descripcion}}: {{p.valor}}
            </mat-checkbox>
        </li>
    </ul>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" mat-dialog-close type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="myForm.invalid" [loading]="loading"
        label="Guardar"></adp-button-icon>
</div>