<div mat-dialog-title class="!text-base" *ngIf="!data.mass">
    Transferir Contacto: {{data.row.contact.nombres}} {{data.row.contact.apellidos}}
</div>

<div mat-dialog-title class="!text-base" *ngIf="data.mass">
    Transferir Contactos
</div>

<mat-dialog-content class="!py-0" style="min-width: 250px;">
    <div *ngIf="data.mass">
        Transferir los siguientes contactos:
        <ul>
            <li *ngFor="let c of data.selects">
                {{c.contact.nombres}} {{c.contact.apellidos}}
            </li>
        </ul>
    </div>
    <form [formGroup]="myForm" class="flex flex-row flex-wrap justify-start items-end content-end">
        <mat-form-field class="w-full">
            <mat-label>Agente</mat-label>
            <mat-select disableOptionCentering formControlName="agente">
                <mat-option>
                    <ngx-mat-select-search formControlName="filter" placeholderLabel="Buscar..."
                        noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let c of users| search: myForm.get('filter').value:['name']"
                    [value]="c.id">{{c.name}}</mat-option>

            </mat-select>
            <mat-error>
                Completa este campo.
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" type="button" mat-dialog-close class="me-4">Cancelar</button>
    <adp-button-icon (clicked)="save()" color="primary" [disabled]="myForm.invalid" [loading]="loading"
        label="Guardar"></adp-button-icon>
</mat-dialog-actions>