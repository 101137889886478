import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'size',
})
export class SizePipe implements PipeTransform {

  transform(numero: number): string {
    if (numero < 1024) {
      return numero + ' B';
    } else if (numero < 1024 * 1024) {
      return (numero / 1024).toFixed(2) + ' KB';
    } else if (numero < 1024 * 1024 * 1024) {
      return (numero / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
      return (numero / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
  }

}
