import {Component, ElementRef, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {CampaignsService} from 'src/app/core/services/api/campaigns.service';

import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Campaign } from 'src/app/core/contracts/models/campaign.interface';

@Component({
    selector: 'app-campaign-labels',
    templateUrl: './campaign-labels.component.html',
    styleUrls: ['./campaign-labels.component.scss']
})
export class CampaignLabelsComponent implements OnInit, OnChanges {

    visible = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    etiquetas = [];

    filteredLabels: Observable<any[]>;
    allLabels: any[] = [];

    @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoLabel') matAutocomplete: MatAutocomplete;

    labelForm = new UntypedFormControl('')

    @Input() campaign: Campaign;
    @Input() words: boolean;
    @Input() label: string;
    @Input() apparience: MatFormFieldAppearance = 'fill';
    @Output() outLabel = new EventEmitter<any>();

    constructor(
        private campaignsService: CampaignsService
    ) {
    }

    ngOnInit(): void {
        this.campaignsService.getLabels().subscribe(response => {
            this.allLabels = response['etiquetas'];
        })


        this.filteredLabels = this.labelForm.valueChanges.pipe(
            startWith(null),
            map((value: string | null) => {
                const val = value ? value.length > 0 : false
                const filt = this.allLabels.filter(fruit => {
                    if (typeof value == 'string') {
                        return this.replaceStrangeCaracters(fruit.slug.toLowerCase()).includes(this.replaceStrangeCaracters(value.toLowerCase().trim()))
                    } else {
                        return true;
                    }
                })
                return val ? filt : [];
            }));
    }

    ngOnChanges() {
        if (this.campaign) {
            this.etiquetas = this.campaign.etiquetas ? this.campaign.etiquetas : [];
        }
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        this.addLabel(value)
        if (input) {
            input.value = '';
        }

    }

    remove(label): void {
        const index = this.etiquetas.findIndex(et => et.slug == label);
        if (index >= 0) {
            this.etiquetas.splice(index, 1);
        }

        if (this.words) {
            this.outLabel.emit(this.etiquetas.map(el => el.slug));
        } else {
            this.outLabel.emit(this.etiquetas);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.value;
        this.addLabel(value);
    }

    addLabel(value) {
        const isString  = typeof value == 'string';
        const slug      = isString ? value : value['slug'];
        const ind       = this.etiquetas.findIndex(et => this.replaceStrangeCaracters(et.slug.toLowerCase()) == this.replaceStrangeCaracters(slug.toLowerCase().trim()));

        if ((value || '')) {
            if (ind == -1) {
                isString ? null : this.etiquetas.push(value);
            }
        }
        this.labelInput.nativeElement.value = '';
        this.labelForm.setValue(null);
        if (this.words) {
            this.outLabel.emit(this.etiquetas.map(el => el.slug));
        } else {
            this.outLabel.emit(this.etiquetas);
        }
    }

    replaceStrangeCaracters(strTHML) {
        return strTHML.replace(/á/gi, 'a')
            .replace(/é/gi, 'e')
            .replace(/í/gi, 'i')
            .replace(/ó/gi, 'o')
            .replace(/ú/gi, 'u')
            // .replace(/ñ/gi, "&Ntilde;")
            .replace(/°/gi, ' ')
            .replace(/\?/gi, '')
            .replace(/¿/gi, '')
            .replace(/\!/gi, '')
            .replace(/¡/gi, '')
            .replace(/¨/gi, '')
            .replace(/®/gi, '')
            .replace(/@/gi, '')
            .replace(/\$/gi, '')
            .replace(/%/gi, '')
            .replace(/Ü/gi, 'u');
    }

}
