import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreakpointService, SIDEBAR_OPTIONS } from 'src/app/core/services/breakpoint.service';
import { MatDrawer, MatDrawerMode, MatDrawerToggleResult } from '@angular/material/sidenav';

@Component({
    selector: 'app-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

    private breakpoint = inject(BreakpointService);
    private translate = inject(TranslateService)

    public backdrop: boolean = false;
    public mode: MatDrawerMode = 'side';
    public status: MatDrawerToggleResult = 'open'
    public currentLang: string = 'es';
    
    @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

    constructor() {
        // TODO: Revisar a futuro si se refactorizará el idioma
        const browserLang: string = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
        this.currentLang = browserLang;
    }

    ngOnInit(): void {
        this.breakpoint.sidebar$.subscribe(result => {
            switch(result) {
                case SIDEBAR_OPTIONS.SMALL:
                    this.setSidebarToSmallScreen();
                break;
                case SIDEBAR_OPTIONS.MEDIUM:
                    this.setSidebarToMediumScreen();
                break;
                case SIDEBAR_OPTIONS.LARGE:
                    this.setSidebarToLargeScreen();
                break;
            }
        });
    }

    private setSidebarToSmallScreen() {
        this.mode = 'over';
        this.backdrop = true;
        this.drawer.open();
                
    }
    private setSidebarToMediumScreen() {
        this.mode = 'side';
        this.backdrop = false;
        this.drawer.close();    
    }
    private setSidebarToLargeScreen() {
        this.mode = 'side';
        this.backdrop = false;
        this.drawer.open();
    }    
}
