import { Injectable } from '@angular/core';
import { Tema } from 'src/app/core/contracts/models/tema';
import { Api } from '../../contracts/classes/api.class';

@Injectable({ providedIn: 'root' })
export class TemasService extends Api{

  getAll() {
    return this.http.get(`${this.url}/temas`);
  }

  create(data: Tema) {
    return this.http.post(`${this.url}/temas`, data);
  }

  update(data: Tema) {
    return this.http.put(`${this.url}/temas/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/temas/${id}`);
  }
}
