import { Injectable } from '@angular/core';
import { UserLegacy } from '../../contracts/models/usuario.model';
import { map } from 'rxjs/operators';
import { UserDto, User } from '../../contracts/models/user.model';
import { Api } from '../../contracts/classes/api.class';
import { Observable } from 'rxjs';
import { ApiResult } from '../../contracts/types/api-response.type';

@Injectable({providedIn: 'root'})
export class UserService extends Api {

  public searchCampaign: string = '';
  public searchPlanMedio: string = '';
  public searchPersona: string = '';
  public searchCustomer: string = '';
  public searchMedio: string = '';
  public searchCompany: string = '';
  public searchNote: string = '';

  cleanAll() {
    this.searchCampaign = '';
    this.searchPlanMedio = '';
    this.searchPersona = '';
    this.searchCustomer = '';
    this.searchMedio = '';
    this.searchCompany = '';
  }

  /** @version API.v2 */
  public all(): Observable<User[]> {
    return this.http.get<ApiResult<User[]>>(`${this.urlv2}/users`)
    .pipe(map(result => result.data));
  }

  /** @version API.v2 */
  public get(id: number): Observable<User> {
    return this.http.get<ApiResult<User>>(`${this.urlv2}/users/${id}`)
    .pipe(map(result => result.data));
  }

  /** @version API.v2 */
  public create(dto: UserDto): Observable<string> {
    return this.http.post<ApiResult<unknown>>(`${this.urlv2}/users`, dto)
    .pipe(map(result => result.message));
  }

  /** @version API.v2 */
  public update(id: number, dto: UserDto): Observable<string> {
    return this.http.put<ApiResult<unknown>>(`${this.urlv2}/users/${id}`, dto)
    .pipe(map(result => result.message));
  }

  /** @version API.v2 */
  public delete(id: number): Observable<string> {
    return this.http.delete<ApiResult<unknown>>(`${this.urlv2}/users/${id}`)
    .pipe(map(result => result.message));
  }

  /**
   * @version API.v1
   * @deprecated
   */
  legacyGetAll() {
    return this.http.get(`${this.url}/users`);
  }

  getCount() {
    return this.http.get(`${this.url}/users/data/getCount`);
  }

  getId(id) {
    return this.http.get(`${this.url}/users/${id}`).pipe(
      map((response: any) => {
        return response.user;
      })
    );
  }
  // Nuevo método agregado
  selectList(): Observable<Array<{id: number; name: string}>> {
    return this.http.get(`${this.url}/users/data/userSelect`)
    .pipe( map( (response: any) => { return response.users as Array<{id: number; name: string}> } ) );
  }

  getListByRole(roleId : number) { //No se usa en ninguna parte del código
    return this.http.get<UserLegacy[]>(`${this.url}/users/data/getListByRole/${roleId}`)
    .pipe( map( (response: any) => { return response.users } ) );
  }
}
