import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { PersonPaginationResponse, PersonDtoSelected, PersonDto } from '../../contracts/models/person_dto.model';
import { PeoplePaginationRequest } from 'app/pages/people/interfaces/pagination-request.interface';
import { Api } from '../../contracts/classes/api.class';
import { ApiResult } from '../../contracts/types/api-response.type';
import { PersonEmail } from '../../contracts/models/email-collection.model';
import { Person } from '../../contracts/models/person.model';

@Injectable({ providedIn: 'root' })
export class PeopleService extends Api {

    private listPeople = new BehaviorSubject<PersonDto[] | PersonDtoSelected[]>([]);
    listPeople$ = this.listPeople.asObservable();

    addPeople(people: PersonDto[] | PersonDtoSelected[]) {
        this.listPeople.next(people);
    }

    getAll() {
        return this.http.get(`${this.url}/personas`);
    }

    public getListPagination(request): Observable<PersonPaginationResponse>
    {
        return this.http.get<PersonPaginationResponse>(`${this.url}/personas${request}`);
    }

    getAllSelect() {
        return this.http.get(`${this.url}/personasData`);
    }

    getVoceros() {
        return this.http.get(`${this.url}/personas/data/getVoceros`);
    }

    getContactos() {
        return this.http.get(`${this.url}/personas/data/getContactos`);
    }

    getContactosSelect() {
        return this.http.get(`${this.url}/personas/contacto/select`);
    }

    searchContactosSelect(search) {
        return this.http.get(`${this.url}/personas/contacto/select?search=${search}`);
    }

    getId(id: number) {
        return this.http.get(`${this.url}/personas/${id}`).pipe(
            map((response: any) => {
                return response.persona;
            })
        );
    }

    create(data: Person) {
        return this.http.post(`${this.url}/personas`, data);
    }

    edit(data: Person) {
        return this.http.put(`${this.url}/personas/${data.id}`, data);
    }

    delete(id: number) {
        return this.http.delete(`${this.url}/personas/${id}`);
    }

    getAllEmails() {
        return this.http.get(`${this.url}/personaEmails`)
    }

    getEmailsByContact(id) {
        return this.http.get(`${this.url}/personaEmails/data/getListByPersona/${id}`)
    }

    deleteEmail(id: number) {
        return this.http.delete(`${this.url}/personaEmails/${id}`);
    }

    /** Refactor */

    public get_paginate_list( request: PeoplePaginationRequest = {
        search: '', 
        page: 1,
        type: 0,
        category: [], 
    }): Observable<PersonPaginationResponse>
    {
        let params = new HttpParams().set('page', request.page);
        request.type > 0 ? params = params.append('type', request.type) : null;
        request.category.length > 0 ? params = params.append('category', request.category.join(',') ) : null;
        request.search.length > 0 ? params = params.append('search', request.search ) : null;

        return this.http.get<PersonPaginationResponse>(
            `${this.urlv2}/personas`, 
            { params: params }
        );
    }

    public getEmailList(): Observable<PersonEmail[]> {
        return this.http.get<ApiResult<PersonEmail[]>>(`${this.urlv2}/emails/by-person`)
        .pipe(map(r => r.data))
    }
    searchPersons(search: string) {
        return this.http.get(`${this.url}/personasData?search=${search}`).pipe(
            map((response: any) => {
                return response.contactos;
            })
        );
    }
}
