import { NgModule } from "@angular/core";
import { CommonModule, NgClass } from "@angular/common";
import { ComponentsRoutingModule } from "./components-routing.module";
import { PipeModule } from "src/app/core/pipes/pipe.module";
import { MaterialModule } from "../material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@siemens/ngx-datatable";
import { TypesOfPhonesFormComponent } from "./types-of-phones/form/types-of-phones-form.component";
import { TypesOfPhonesListComponent } from "./types-of-phones/list/types-of-phones-list.component";
import { TypesOfEmailsListComponent } from "./types-of-emails/list/types-of-emails-list.component";
import { TypesOfEmailsFormComponent } from "./types-of-emails/form/types-of-emails-form.component";
import { SocialNetworksFormComponent } from "./social-networks/form/social-networks-form.component";
import { SocialNetworksListComponent } from "./social-networks/list/social-networks-list.component";
import { TypesOfAddressesListComponent } from "./types-of-addresses/list/types-of-addresses-list.component";
import { TypesOfAddressesFormComponent } from "./types-of-addresses/form/types-of-addresses-form.component";
import { AsignarPlataformasFormComponent } from "./asignar-plataformas/form/asignar-plataformas-form.component";
import { AsignarPlataformasListComponent } from "./asignar-plataformas/list/asignar-plataformas-list.component";
import { AsignarProgramasListComponent } from "./asignar-programas/list/asignar-programas-list.component";
import { AsignarProgramasFormComponent } from "./asignar-programas/form/asignar-programas-form.component";
import { AsignarContactosFormComponent } from "./asignar-contactos/form/asignar-contactos-form.component";
import { AsignarContactosListComponent } from "./asignar-contactos/list/asignar-contactos-list.component";
import { AsignarProgramasLinkComponent } from "./asignar-programas/link/asignar-programas-link.component";
import { AsignarMedioFormComponent } from "./asignar-medio/form/asignar-medio-form.component";
import { AsignarMedioListComponent } from "./asignar-medio/list/asignar-medio-list.component";
import { AsignarEncargadosListComponent } from "./asignar-encargados/list/asignar-encargados-list.component";
import { AsignarEncargadosFormComponent } from "./asignar-encargados/form/asignar-encargados-form.component";
import { AsignarClientesFormComponent } from "./asignar-clientes/form/asignar-clientes-form.component";
import { AsignarClientesListComponent } from "./asignar-clientes/list/asignar-clientes-list.component";
import { AsignarVocerosListComponent } from "./asignar-voceros/list/asignar-voceros-list.component";
import { AsignarVocerosFormComponent } from "./asignar-voceros/form/asignar-voceros-form.component";
import { AsignarPlanMediosFormComponent } from "./asignar-plan-medios/form/asignar-plan-medios-form.component";
import { AsignarPlanMediosListComponent } from "./asignar-plan-medios/list/asignar-plan-medios-list.component";
import { ScheduleFormComponent } from "./schedule/form/schedule-form.component";
import { ScheduleListComponent } from "./schedule/list/schedule-list.component";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ContactFormComponent } from "./contact-form/contact-form.component";
import { ChangeStatusComponent } from "./change-status/change-status.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { RateCampaignComponent } from "./rate-campaign/rate-campaign.component";
import { DetailDivComponent } from "./detail-div/detail-div.component";
import { CampaignLabelsComponent } from "./campaign-labels/campaign-labels.component";
import { ValidateModalComponent } from "./validate-modal/validate-modal.component";
import { AsignarCampanaComponent } from "./asignar-campana/asignar-campana.component";
import { DetailKanbanComponent } from "./detail-kanban/detail-kanban.component";
import { ContactTransferComponent } from "./contact-transfer/contact-transfer.component";
import { SendEmailDialogModule } from "./send-email-dialog/send-email-dialog.module";
import { MatSliderModule } from "@angular/material/slider";
import { EmailEditComponent } from "./email-edit/email-edit.component";
import { NewPersonComponent } from "./new-person/new-person.component";
import { SpeedNetworkModule } from "./speed-network/speed-network.module";
import { ListSelectedPeopleModule } from "./list-selected-people/list-selected-people.module";
import { ViewImpactoComponent } from "./view-impacto/view-impacto.component";
import { MultipleSelectAllComponent } from "./multiple-select-all/multiple-select-all.component";
import { AutocompleteChipSelectComponent } from "./autocomplete-chip-select/autocomplete-chip-select.component";
import { JournalistFormComponent } from './journalist-form/journalist-form.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ButtonIconModule } from "../shared/components/button-icon/button-icon.module"; 

@NgModule({
  declarations: [
    AsignarCampanaComponent,
    AsignarClientesFormComponent,
    AsignarClientesListComponent,
    AsignarContactosFormComponent,
    AsignarContactosListComponent,
    AsignarEncargadosFormComponent,
    AsignarEncargadosListComponent,
    AsignarMedioFormComponent,
    AsignarMedioListComponent,
    AsignarPlanMediosFormComponent,
    AsignarPlanMediosListComponent,
    AsignarPlataformasFormComponent,
    AsignarPlataformasListComponent,
    AsignarProgramasFormComponent,
    AsignarProgramasLinkComponent,
    AsignarProgramasListComponent,
    AsignarVocerosFormComponent,
    AsignarVocerosListComponent,
    CampaignLabelsComponent,
    ChangeStatusComponent,
    ContactFormComponent,
    ContactTransferComponent,
    DetailDivComponent,
    DetailKanbanComponent,
    EmailEditComponent,
    MultipleSelectAllComponent,
    NewPersonComponent,
    RateCampaignComponent,
    ScheduleFormComponent,
    ScheduleListComponent,
    SocialNetworksFormComponent,
    SocialNetworksListComponent,
    TypesOfAddressesFormComponent,
    TypesOfAddressesListComponent,
    TypesOfEmailsFormComponent,
    TypesOfEmailsListComponent,
    TypesOfPhonesFormComponent,
    TypesOfPhonesListComponent,
    ValidateModalComponent,
    ViewImpactoComponent,
    AutocompleteChipSelectComponent,
    JournalistFormComponent,
    SearchBoxComponent
  ],
  imports: [
    CommonModule,
    ComponentsRoutingModule,
    FormsModule,
    InfiniteScrollModule,
    LazyLoadImageModule,
    ListSelectedPeopleModule,
    MatAutocompleteModule,
    MaterialModule,
    MatSliderModule,
    NgxDatatableModule,
    NgxMatSelectSearchModule,
    PipeModule,
    ReactiveFormsModule,
    SendEmailDialogModule,
    SpeedNetworkModule,
    MatButtonModule,
    MatIconModule,
    ButtonIconModule,
    NgClass
  ],
  exports: [
    AsignarCampanaComponent,
    AsignarClientesListComponent,
    AsignarContactosListComponent,
    AsignarEncargadosListComponent,
    AsignarMedioListComponent,
    AsignarPlanMediosListComponent,
    AsignarPlataformasListComponent,
    AsignarProgramasListComponent,
    AsignarVocerosListComponent,
    AutocompleteChipSelectComponent,
    CampaignLabelsComponent,
    ChangeStatusComponent,
    ContactFormComponent,
    ContactTransferComponent,
    DetailDivComponent,
    DetailKanbanComponent,
    EmailEditComponent,
    MultipleSelectAllComponent,
    RateCampaignComponent,
    ScheduleListComponent,
    SocialNetworksListComponent,
    TypesOfAddressesListComponent,
    TypesOfEmailsListComponent,
    TypesOfPhonesListComponent,
    ValidateModalComponent,
    JournalistFormComponent
  ],
})
export class ComponentsModule {}
