import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePipe } from './image.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { SearchPipe } from './search.pipe';
import { ListDetailPipe } from './list-detail.pipe';
import { DolarPipe } from './dolar.pipe';
import { SizePipe } from './size.pipe';



@NgModule({
  declarations: [
    ImagePipe,
    DateAgoPipe,
    SearchPipe,
    ListDetailPipe,
    DolarPipe,
    SizePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SizePipe,
    ImagePipe,
    DateAgoPipe,
    SearchPipe,
    ListDetailPipe,
    DolarPipe
  ]
})
export class PipeModule { }
