<div mat-dialog-title class="!text-base">{{title}}</div>

<div mat-dialog-content class="!py-0">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="grid gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Vocero</mat-label>
                <mat-select disableOptionCentering formControlName="vocero_id" (selectionChange)="changeVocero()" required>
                    <mat-option>
                        <ngx-mat-select-search formControlName="filter" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="-1">Agregar Nuevo</mat-option>
                    <mat-option *ngFor="let v of filteredVoceros$ | async" [value]="v.id">
                        {{v.nombres}} {{v.apellidos}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Observación</mat-label>
                <input matInput type="text" autocomplete="off" formControlName="observacion">
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Vinculo con campaña</mat-label>
                <input matInput type="text" autocomplete="off" formControlName="vinculo">
            </mat-form-field>
        </div>
    </form>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" mat-dialog-close type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="myForm.invalid" [loading]="loading"
        label="Guardar"></adp-button-icon>
</div>