import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DynamicSelectService} from '../../core/services/api/dynamic-select.service';
import {BitacoraService} from '../../core/services/api/bitacora.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {status} from '../../shared/general/datageneral';
import { Swal2 } from 'src/app/core/utils/swal2.util';

@Component({
    selector: 'app-change-status',
    templateUrl: './change-status.component.html',
    styleUrls: ['./change-status.component.scss']
})
export class ChangeStatusComponent implements OnInit {
    statusPlanForm: UntypedFormGroup = this.formBuilder.group({
        resumen: [null, Validators.required],
        type: [null, Validators.required]
    });

    buttonLoading = false;

    actualStatus = '';
    nextStatus = '';
    types: Array<any> = [];

    filteredOptions: Observable<any>;

    status = status;
    colors = ['badge-info', 'badge-primary', 'badge-warning', 'badge-success', 'badge-danger'];

    classStatus = '';
    classNextStatus = '';

    constructor(
        public dialogRef: MatDialogRef<ChangeStatusComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { edit: boolean, row: any, id?: number, status: any, actual: number, plan: any },
        private formBuilder: UntypedFormBuilder,
        private dynamicSelectService: DynamicSelectService,
        private bitacoraService: BitacoraService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.actualStatus    = this.status.filter(el => el.id == this.data.actual)[0].name;
        this.classStatus     = this.colors[this.data.actual - 1];
        this.nextStatus      = this.data.status.name;
        this.classNextStatus = this.colors[this.data.status.id - 1];

        this.dynamicSelectService.getListBySlug('comunicacion').subscribe(response => {
            this.types = response['tipoAtributos'];
            this.filteredOptions = this.statusPlanForm.get('type').valueChanges.pipe(
                startWith(''),
                map(value => {
                    const val = typeof value === 'object' ? value.name : value
                    return this.types.filter(el => val ? el.name.toLowerCase().includes(val.toLowerCase()) : true)
                })
            );
        })
    }

    saveStatus() {
        this.statusPlanForm.disable();
        this.buttonLoading = true;

        const newBitacora = {
            idDetallePlanMedio: this.data.row.id,
            estado:             this.data.status.id,
            observacion:        this.statusPlanForm.value['resumen'],
            idTipoComunicacion: this.statusPlanForm.value['type']['id']
        }

        this.bitacoraService.create(newBitacora).subscribe({
            next: (response: any) => {
                this.swal.showToast(response.message, 'success')
                this.dialogRef.close({
                    action: true
                })
            },
            error:(error) => {
                let message:string ='';
                switch (error.status) {
                    case 0:
                        message = 'La carga de la bitácora falló. Porfavor inténtelo nuevamente'
                        break;
                    case 504:
                        message = "Hubo un retraso en el tiempo de respuesta del servidor accedido. Porfavor inténtelo nuevamente"
                        break;
                    default:
                        message = error.error.message ? error.error.message : error.message
                }
                this.swal.showAlert(message, 'error');
                this.statusPlanForm.enable();
                this.buttonLoading = false;
            }
        });
    }

    cancel() {
        this.dialogRef.close({
            action: false
        });
    }

}
