import {Component, Inject, OnInit} from '@angular/core';
import {Validators, FormControl, FormBuilder} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

import {CustomerService} from 'src/app/core/services/api/customer.service';
import {SpokesmenService} from 'src/app/core/services/api/spokesmen.service';
import {PeopleService} from 'src/app/core/services/api/people.service';
import {NewPersonComponent} from '../../new-person/new-person.component';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { ClienteEncargados } from 'src/app/core/contracts/models/cliente-encargados';

@Component({
    selector: 'app-asignar-encargados-form',
    templateUrl: './asignar-encargados-form.component.html',
    styleUrls: ['./asignar-encargados-form.component.scss']
})
export class AsignarEncargadosFormComponent implements OnInit {
    public loading:boolean = false;

    myForm = this.formBuilder.group({
        persona_id:new FormControl<number|null>({value: null, disabled: true},[Validators.required]),
        tipoEncargado:new FormControl<string|null>(null,Validators.required),
        filter: new FormControl<string|null>(null) 
        });

    encargado: ClienteEncargados = {
        cliente_id: null,
        idEncargado: null,
        tipoEncargado: null,
    }

    personas: any[] = [];

    title = 'Agregar encargado';

    loader = true;

    constructor(
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AsignarEncargadosFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { row: ClienteEncargados, id: number },
        private peopleService: PeopleService,
        private customerService: CustomerService,
        private spokesmenService: SpokesmenService,
        private swal: Swal2
    ) {
        if (data.row) {
            this.title = 'Editar encargado';
        }
    }

    ngOnInit(): void {

        if (this.data.row) {
            this.personas = [this.data.row.encargado]
            this.myForm.controls.persona_id.setValue(this.data.row.idEncargado);
            this.myForm.controls.tipoEncargado.setValue(this.data.row.tipoEncargado);
        } else {
            this.peopleService.getAllSelect().subscribe((response: any) => {
                const {contactos} = response;
                this.personas = contactos.map(ct => {
                    ct.completeName = `${ct.nombres} ${ct.apellidos}`;
                    return ct;
                })
                this.myForm.controls.persona_id.enable({emitEvent: false});
            });
        }

    }

    onSubmit() {
       this.encargado={
        cliente_id: this.data.id,
        idEncargado: this.myForm.controls.persona_id.value,
        tipoEncargado: this.myForm.controls.tipoEncargado.value
       }
       this.loading=true;

        if (this.data.row) {
            this.encargado.id = this.data.row.id;
            this.customerService.updateClienteEncargados(this.encargado).subscribe((response: any) => {
                this.loading=false;
                this.swal.showToast(response.message, 'success');
                this.dialogRef.close(response);
            }, error => {
                if (error.status === 400) {
                    this.error(error);
                }
                this.loading=false
            });
        } else {
            let person = this.personas.find(pr => pr.id == this.myForm.get('persona_id').value);
            this.customerService.createClienteEncargados(this.encargado).subscribe((response: any) => {

                this.swal.showToast(response.message, 'success');
                if (person.tiposPersona.includes('2')) {
                    let cliente = {
                        cliente_id: this.data.id,
                        idVocero: this.myForm.get('persona_id').value,
                        cargo: this.getCargo(this.myForm.get('tipoEncargado').value)
                    }
                    this.spokesmenService.createClienteVoceros(cliente).subscribe((response2: any) => {
                        this.dialogRef.close(response);
                          this.loading=false
                        this.swal.showToast(response2.message, 'success');
                    }, error => {
                        if (error.error.errors.idVocero) {
                            this.loading=false
                            this.dialogRef.close(response);
                        } else {
                            this.swal.showToast(error.error.message, 'error')
                            this.loading=false
                            this.dialogRef.close(response);
                        }


                    });
                } else {
                    this.dialogRef.close(response);
                }
                //this.dialogRef.close(response);
            }, error => {
                if (error.status === 400) {
                    this.error(error);
                }
                this.loading=false
            });
        }
    }

    getCargo(num) {
        let cargos = {
            1: 'Propietario',
            2: 'Representante legal',
            3: 'Coordinador de Marketing',
            4: 'Coordinador de comunicación'
        }

        return cargos[num]
    }

    error(error: any) {
        if (error.error.errors.persona_id) {
            this.swal.showToast(error.error.errors.persona_id[0], 'error');
        }
    }

    newPerson() {
        if (this.myForm.get('persona_id').value == -1) {
            this.dialog.open(NewPersonComponent, {
                data: {tipo: [], idCliente: null},
                width: '600px'
            }).afterClosed().subscribe(res => {
                if (res) {
                    this.personas.push(res);
                    res.completeName = res.nombres + ' ' + res.apellidos;
                    this.myForm.get('persona_id').setValue(res.id);
                } else {
                    this.myForm.get('persona_id').setValue(null);
                }
            });
        }
    }
}
