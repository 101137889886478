import { Component, Input } from '@angular/core';
import { DEFAULT_AVATAR } from 'src/app/core/utils/constants';
import { environment } from 'src/environments/environment';

const DEFAULT_USERNAME = 'Agente de Prensa';

@Component({
    selector: 'adp-sidebar-avatar',
    templateUrl: './sidebar-avatar.component.html',
})
export class SidebarAvatarComponent {

    public avatarImage: string = DEFAULT_AVATAR;
    public username: string = DEFAULT_USERNAME;

    @Input() set image(image: Blob|File|string|undefined) {
        image
          ? this.avatarImage = `${environment.app_urls.source}/picture/users/${image}`
          : this.avatarImage = DEFAULT_AVATAR;
        
    } 
    @Input() set label(label: string|undefined) {
        label
          ? this.username = label
          : this.username = DEFAULT_USERNAME;
    } 
    
}
