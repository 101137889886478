import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-detail-div',
    templateUrl: './detail-div.component.html',
    styleUrls: ['./detail-div.component.scss']
})
export class DetailDivComponent implements OnInit {

    @Input() row: any;

    constructor() {
    }

    ngOnInit(): void {
    }

}
