<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form [formGroup]="phoneForm" class="grid gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="idTipoTelefono" name="idTipoTelefono">
                    <mat-option>Ninguno</mat-option>
                    <mat-option *ngFor="let p of phones" [value]="p.id">{{p.name}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>

        <div class="grid sm:grid-cols-2 gap-x-4 gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Código país</mat-label>
                <mat-select formControlName="country" disableOptionCentering>
     |               <mat-select-trigger>
                        <span *ngIf="selectedC">
                            {{selectedC.code}} <b>{{selectedC.dial_code}}</b>
                        </span>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search formControlName="filter" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let c of phonesCode| search: phoneForm.get('filter').value:['name_es']"
                        (click)="selectCountry(c)" [value]="c.dial_code">
                        {{c.name_es}}
                    </mat-option>
                </mat-select>
                <button mat-button *ngIf="phoneForm.get('country').value" matSuffix  aria-label="Clear"
                    (click)="clear()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Número</mat-label>
                <input matInput type="phone" (keypress)="onlyNumber($event)" autocomplete="off"
                    formControlName="telefono">
                <mat-error>
                    <span *ngIf="phoneForm.get('telefono').errors?.['required']"> Completa este campo.</span>
                    <span *ngIf="phoneForm.get('telefono').errors?.['max']length"> Máximo 12 dígitos</span>
                    <span *ngIf="phoneForm.get('telefono').errors?.['pattern']"> Caracteres inválidos</span>
                </mat-error>

            </mat-form-field>
        </div>
    </form>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" (click)="cancel()" type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="phoneForm.invalid" 
        label="Agregar"></adp-button-icon>
</div>