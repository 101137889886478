import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SnackBarService } from 'src/app/core/services/snackbar.service';

export type ConnectionStatus = 'connected' | 'disconnected' | 'noInternet';

@Injectable({providedIn: 'root'})
export class ConnectionService {
  private notification = inject(SnackBarService);

  private onlineStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);
  public onlineStatus$ = this.onlineStatus.asObservable();

  get isConnected() {
    return this.onlineStatus.value;
  }

  constructor() {
    window.addEventListener('online', () => this.connected());
    window.addEventListener('offline', () => this.disconnected());
  }

  public showToastError() {
    this.notification.renderConnection('noInternet', 'adp-danger-snackbar')
  }

  private connected() {
    this.notification.renderConnection('connected', 'adp-success-snackbar')
    this.onlineStatus.next(true);
  }

  private disconnected() {
    this.notification.renderConnection('disconnected', 'adp-warning-snackbar')
    this.onlineStatus.next(false);
  }
}
