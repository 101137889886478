import { NgModule } from '@angular/core';
import { DetailConfi } from './general/datageneral';
import { PlacesItems } from './general/places';

@NgModule({
  declarations: [],
  exports: [],
  providers: [ DetailConfi, PlacesItems ]
})
export class SharedModule { }
