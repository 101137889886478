<div style="margin-bottom: 10px;">
    <div class="px-4 flex flex-row flex-wrap justify-between items-center content-center">
        <h3 style="font-size: 16px;">Encargado</h3>
        <button type="button" mat-raised-button mat-button-sm mat-button-sm color="primary"
            *ngIf="addPermission" [disabled]="isLoading" class="me-4 mb-4"
            (click)="openDialog()">
            Agregar encargado
        </button>
    </div>


    <ngx-datatable class="material min" [messages]="messages" [columnMode]="'force'" [headerHeight]="45"
        [footerHeight]="45" [loadingIndicator]="isLoading" [rowHeight]="getRowHeight" [scrollbarV]="true" [scrollbarH]="true" [rows]="rows">

        <ngx-datatable-column name="#" [width]="10" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Representante" prop="encargado">
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{value.nombres}} {{value.apellidos}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Cargo" prop="tipoEncargado">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngIf="value===1">Propietario</div>
                <div *ngIf="value===2">Representante legal</div>
                <div *ngIf="value===3">Coordinador de Marketing</div>
                <div *ngIf="value===4">Coordinador de comunicación</div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Correo" prop="encargado.emails">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngFor="let em of value">{{em.email}}</div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Teléfono" prop="encargado.telefonos">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngFor="let tl of value">{{tl.telefono}}</div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Acciones" prop="id" [width]="120" [canAutoResize]="false" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button mat-stroked-button mat-button-sm color="primary"
                    *ngIf="editPermission" type="button" class="me-2"
                    (click)="edit(row)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-stroked-button mat-button-sm 
                    *ngIf="deletePermission" type="button" (click)="delete(value)">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>