<div>
    <div class="px-4 my-4 flex flex-row flex-wrap justify-between items-center content-center">
        <h3 class="m-0">Plataforma</h3>
        <button type="button" mat-raised-button color="primary" *ngIf="addPermission" (click)="openDialog()">
            Agregar plataforma
        </button>
    </div>


    <ngx-datatable class="material min" [messages]="messages" [columnMode]="'force'" [headerHeight]="45" [footerHeight]="45"
    [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true" [rows]="rows">

        <ngx-datatable-column name="#" [width]="10" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Plataforma" prop="plataforma_clasificacion.plataforma.descripcion">
        </ngx-datatable-column>
        <ngx-datatable-column name="Clasificación" prop="plataforma_clasificacion.descripcion"></ngx-datatable-column>
        <ngx-datatable-column name="Nº Señal / URL" prop="valor"></ngx-datatable-column>
        <ngx-datatable-column name="Alcance" prop="alcance"></ngx-datatable-column>
        <ngx-datatable-column name="Valorización" prop="valorizacion_plataforma">
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{ value && value !== '0' ? value + ' USD' : '' }}
            </ng-template>
        </ngx-datatable-column>       
        <ngx-datatable-column name="Observación" prop="observacion"></ngx-datatable-column>
        <ngx-datatable-column name="Acciones" prop="id" [width]="120" [canAutoResize]="false" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button mat-stroked-button mat-button-sm color="primary"
                    *ngIf="editPermission" type="button" class="me-2"
                    (click)="edit(row)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-stroked-button mat-button-sm 
                    *ngIf="deletePermission" type="button" (click)="delete(value)">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>