<div  mat-dialog-title class="!text-base">
    {{title}}
</div>

<div mat-dialog-content class="py-0">
    <form #addressForm="ngForm">
        <div class="grid gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Tipo</mat-label>
                <mat-select [(ngModel)]="address.idTipoDireccion" name="idTipoDireccion" required>
                    <mat-option>Ninguno</mat-option>
                    <mat-option *ngFor="let a of addresses" [value]="a.id">{{a.name}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
    
            <mat-form-field class="w-full">
                <mat-label>Dirección</mat-label>
                <input matInput required type="text" autocomplete="off" [(ngModel)]="address.direccion" name="direccion">
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
        </div>
        <ng-container *ngIf="changeDepartment$ | async"></ng-container>
        <ng-container *ngIf="changeProv$ | async"></ng-container>
        <form [formGroup]="districtForm" style="margin:10px 0;" *ngIf="data.district">
            <div class="grid sm:grid-cols-3 gap-x-4 gap-y-2">
                <mat-form-field class="w-full">
                    <mat-label>Departamento</mat-label>
                    <mat-select formControlName="departamento" disableOptionCentering>
                        <mat-option>
                            <ngx-mat-select-search formControlName="filter1" placeholderLabel="Buscar..."
                                noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let c of departmentos| search: districtForm.get('filter1').value:['name']" [value]="c.id">
                            {{c.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label>Provincia</mat-label>
                    <mat-select formControlName="provincia" disableOptionCentering>
                        <mat-option>
                            <ngx-mat-select-search formControlName="filter2" placeholderLabel="Buscar..."
                                noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let c of provincias| search: districtForm.get('filter2').value:['name']" [value]="c.id">
                            {{c.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label>Distrito</mat-label>
                    <mat-select formControlName="distrito" disableOptionCentering>
                        <mat-option>
                            <ngx-mat-select-search formControlName="filter3" placeholderLabel="Buscar..."
                                noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let c of distritos| search: districtForm.get('filter3').value:['name']" [value]="c.id">
                            {{c.name}}
                        </mat-option>
                    </mat-select>
                    <button  *ngIf="districtForm.get('distrito').value" matSuffix mat-icon-button aria-label="Clear"
                        (click)="clear('distrito')">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </form>
    </form>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" (click)="cancel()" type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit(addressForm)" color="primary" [disabled]="addressForm.invalid" [loading]="!buttonSubmit"
        label="Agregar"></adp-button-icon>
</div>
