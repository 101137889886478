import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Option} from 'app/core/contracts/models/option';
import { Schedule } from 'src/app/core/contracts/models/schedule';
import {DynamicSelectService} from 'src/app/core/services/api/dynamic-select.service';

@Component({
    selector: 'app-schedule-form',
    templateUrl: './schedule-form.component.html',
    styleUrls: ['./schedule-form.component.scss']
})
export class ScheduleFormComponent implements OnInit {

    horarios: Option[] = [];
    horario: Schedule = {
        idTipoHorario: null,
        tipo_horario: null,
        descripcion: null,
        periodicidad: null,
        horaInicio: null,
        horaFin: null
    };

    title = 'Agregar horario';
    buttonSubmit = true;

    constructor(
        public dialogRef: MatDialogRef<ScheduleFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Schedule,
        private dynamicSelectService: DynamicSelectService
    ) {
        if (data) {
            this.title = 'Editar horario';
            data.periodicidad = data.periodicidad.split('-');
            this.horario.tipo_horario = data.tipo_horario;
            this.horario.idTipoHorario = data.idTipoHorario;
            this.horario.descripcion = data.descripcion;
            this.horario.horaInicio = data.horaInicio;
            this.horario.periodicidad = data.periodicidad;
            this.horario.horaFin = data.horaFin;
        }
    }

    ngOnInit(): void {

        this.dynamicSelectService.getListBySlug('horario').subscribe((response: any) => {
            this.horarios = response.tipoAtributos;
        });
    }

    onSubmit() {
        this.horario.tipo_horario = this.horarios.find(h => h.id === this.horario.idTipoHorario);
        this.dialogRef.close({...this.horario});
    }

    cancel() {
        this.dialogRef.close(this.data);
    }
}
