import { Injectable } from '@angular/core';
import { Category, CategoryDTO } from 'src/app/core/contracts/models/category';
import { map, Observable } from 'rxjs';
import { Api } from '../../contracts/classes/api.class';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends Api {

  legacyGetAll()
  {
    return this.http.get(`${this.url}/categorias`);
  }

  create(data: Category) {
    return this.http.post(`${this.url}/categorias`, data);
  }

  update(data: Category) {
    return this.http.put(`${this.url}/categorias/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/categorias/${id}`);
  }

  /** Refactor */
  public getAll(): Observable<CategoryDTO[]>
  {
    return this.http.get<{ready: boolean, categorias: CategoryDTO[]}>(`${this.url}/categorias`)
    .pipe( map( response => response.categorias ));
  }
}
