import { Injectable } from '@angular/core';

export const contact_type = { 1: 'Contacto', 2: 'Vocero' };

export interface SelectData<T = any> { id: T; name: string; }
interface Value { value: number; name: string; }

export const tiers: SelectData<number>[] = [
  { id: 1, name: 'Tier 1' },
  { id: 2, name: 'Tier 2' },
  { id: 3, name: 'Tier 3' }
];

export const typesNotes: SelectData<number>[] = [
  { id: 1, name: 'Nota' },
  { id: 2, name: 'Entrevista' },
  { id: 3, name: 'Reseña' },
  { id: 4, name: 'Opinión' },
  { id: 5, name: 'Mención'},
];

export const stages: SelectData<number>[] = [
  { id: 1, name: 'Primicia' },
  { id: 2, name: 'Lanzamiento' },
  { id: 3, name: 'Mantenimiento' },
];

export const statusPlan: SelectData<number>[] = [
  { id: 0, name: 'Planificado' },
  { id: 1, name: 'Aprobado' },
  { id: 2, name: 'Terminado' }
]

export const status: SelectData<number>[] = [
  { id: 1, name: 'NO CONTACTADO' },
  { id: 2, name: 'SEGUIMIENTO'   },
  { id: 3, name: 'RESPONDIDO'    },  //Agregado desde el 21/03/2023
  { id: 4, name: 'APROBADO'      },
  { id: 5, name: 'REALIZADO'     },
  { id: 6, name: 'RESULTADO'     }
]

export const statusPublic: SelectData<number>[] = [
  { id: 1, name: 'PUBLICADO' },
  { id: 2, name: 'RECHAZADO' },
  { id: 3, name: 'CANCELADO' }
]

export const statusBitacora:any[] = [
  { id: 1, label: 'No contactado', estado: 1, estadoPublicado: 0, ref: 1},
  { id: 2, label: 'Seguimiento',   estado: 2, estadoPublicado: 0, ref: 2},
  { id: 3, label: 'Respondido',    estado: 3, estadoPublicado: 0, ref: 3},
  { id: 4, label: 'Aprobado',      estado: 4, estadoPublicado: 0, ref: 4},
  { id: 5, label: 'Realizado',     estado: 5, estadoPublicado: 0, ref: 5},
  { id: 6, label: 'Publicado',     estado: 6, estadoPublicado: 1, ref: 7},
  { id: 7, label: 'Rechazado',     estado: 6, estadoPublicado: 2, ref: 8},
  { id: 8, label: 'Cancelado',     estado: 6, estadoPublicado: 3, ref: 9}
]

export const searchStatus: SelectData<string>[] = [
  { id: 'st-1',   name: 'NO CONTACTADO' },
  { id: 'st-2',   name: 'SEGUIMIENTO'   },
  { id: 'st-3',   name: 'RESPONDIO'     }, //Agregado desde el 21/03/2023
  { id: 'st-4',   name: 'APROBADO'      },
  { id: 'st-5',   name: 'REALIZADO'     },
  { id: 'stp-1',  name: 'PUBLICADO'     },
  { id: 'stp-2',  name: 'RECHAZADO'     },
  { id: 'stp-3',  name: 'CANCELADO'     }
]

export const objectives: Value[] = [
  { name: 'Posicionamiento', value: 1 },
  { name: 'Ventas',          value: 2 },
  { name: 'Branding',        value: 3 },
  { name: 'Performance',     value: 4 },
  { name: 'Promoción',       value: 5 },
  { name: 'Lanzamiento',     value: 6 }
]

export const audience: Value[] = [
  { name: 'APT', value: 1 },
  { name: '12+', value: 2 },
  { name: '14+', value: 3 },
  { name: '18+', value: 4 },
  { name: '30+', value: 5 },
  { name: '50+', value: 6 }
]

export const typePublic: Value[] = [
  { name: 'Empresas', value: 1 },
  { name: 'Personas', value: 2 }
]

@Injectable()
export class DetailConfi {
  constructor() { }

  getStatusMuestra(data) : 'Sin Muestra' | 'Registrado' | 'Enviado' | 'Verificado' {
    if (data.muestrasRegistradas) {
      if (data.muestrasVerificadas) { return 'Verificado' } 
      else {
        if (data.muestrasEnviadas) { return 'Enviado' } 
        else { return 'Registrado' }
      }
    } else {
      return 'Sin Muestra'
    }
  }

  getStatus(el) {
    if (el.statusPublicado == 0) {
      const id = this.getIdStatus(el)
      return status.find(stp => stp.id == id)
    } else {
      return statusPublic.find(stp => stp.id == el.statusPublicado)
    }

  }

  getIdStatus(el) {
    if (el.idDetallePlanMedioPadre) {
      if (el.detallePlanMedioPadre) {
        return el.detallePlanMedioPadre.bitacoras.length ? el.detallePlanMedioPadre.bitacoras[el.detallePlanMedioPadre.bitacoras.length - 1]['estado'] : 1
      } else {
        return 5
      }

    } else {
      let st = el.bitacoras.length ? el.bitacoras[el.bitacoras.length - 1]['estado'] : 1
      return el.statusPublicado < 2 ? st : 1 - el.statusPublicado
    }
  }

  getResumen(el){
    if (el.idDetallePlanMedioPadre) {
      if (el.detallePlanMedioPadre) {
        return el.detallePlanMedioPadre.bitacoras.length ? el.detallePlanMedioPadre.bitacoras[el.detallePlanMedioPadre.bitacoras.length - 1]['observacion'] : 'nuevo'
      } else {
        return 'nuevo'
      }

    } else {
      return el.bitacoras.length ? el.bitacoras[el.bitacoras.length - 1]['observacion'] : 'nuevo'   
    }
  }
}
