import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import {ScheduleFormComponent} from '../form/schedule-form.component';

@Component({
    selector: 'app-schedule-list',
    templateUrl: './schedule-list.component.html',
    styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {

    @Output() onSchedule = new EventEmitter<any>();
    @Input() data: any[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    constructor(
        public dialog: MatDialog,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
    }

    openDialog(row?: any) {
        const dialogRef = this.dialog.open(ScheduleFormComponent, {
            data: row,
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addSchedule(result);
            }
        });
    }

    addSchedule(schedule: any) {
        schedule.periodicidad = schedule.periodicidad.join('-');
        const rows = [...this.data];
        rows.push(schedule);
        this.data = rows;
        this.onSchedule.emit(rows);
    }

    editSchedule(row: any, id: number) {
        const rows = [...this.data];
        rows.splice(id, 1);
        this.data = rows;
        this.openDialog(row);
        this.onSchedule.emit(rows);
    }

    deleteSchedule(id) {
        this.swal.deleteItem().then(val => {
            if (val) {
                const rows = [...this.data];
                rows.splice(id, 1);
                this.data = rows;
                this.onSchedule.emit(rows);
                this.swal.showTitleAlert('Eliminado', 'Registro eliminado con éxito', 'success');
            }
        });
    }
}
