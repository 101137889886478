<button 
    class="w-full" 
    mat-raised-button 
    [color]="color" 
    [attr.type]="type" 
    [disabled]="this.disableButton"
    (click)="onClicked()"
>

    <mat-icon iconPositionEnd [class.animate-spin]="loading">
        {{iconButton}}
    </mat-icon>

    {{label}}

</button>