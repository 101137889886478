import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ProgramaContactosService} from 'src/app/core/services/api/programa-contactos.service';
import {AsignarMedioFormComponent} from '../form/asignar-medio-form.component';
import {EmailEditComponent} from 'app/components/email-edit/email-edit.component';
import {take} from 'rxjs/operators';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
    selector: 'app-asignar-medio-list',
    templateUrl: './asignar-medio-list.component.html',
    styleUrls: ['./asignar-medio-list.component.scss']
})
export class AsignarMedioListComponent implements OnInit {

    @Input() id: any;
    @Input() emails: boolean;
    @Output() emailChange = new EventEmitter<any>();

    rows: any[] = [];
    temp: any[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    sending = -1

    addPermission = this.auth.isPermit('persona.medio.add');
    editPermission = this.auth.isPermit('persona.medio.edit');
    deletePermission = this.auth.isPermit('persona.medio.delete');
    togglePermission = this.auth.isPermit('persona.medio.activo');

    constructor(
        public dialog: MatDialog,
        private programaContactosService: ProgramaContactosService,
        private auth: AuthService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.getAll();
    }


    getAll() {
        this.programaContactosService.getListByContacto(this.id).subscribe((response: any) => {
            const {programaContactos} = response;
            this.rows = programaContactos.map(pr => {
                pr['height'] = pr.medioPlataformas.length ? 50 + ((pr.medioPlataformas.length - 1) * 20) : 50;
                return pr;
            });
        });
    }

    add() {
        this.openDialog();
    }

    edit(row: any) {
        this.openDialog(row);
    }

    openDialog(row?: any) {
        const dialogRef = this.dialog.open(AsignarMedioFormComponent, {
            data: {row, id: Number(this.id)},
            width: '800px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAll();
            }
        });
    }

    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.programaContactosService.delete(id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }


    desactivate(row) {
        const text = row.activo == 1 ? 'Se desactivará el medio, ¿Desea continuar?' : 'Se activará el medio, ¿Desea continuar?';
        this.swal.desactiveItem(text).then(val => {
            if (val) {
                this.sending = row.id
                if (row.activo == 1) {
                    if (this.emails) {
                        this.openEmailDialog(row);
                    } else {
                        this.desactiveMedio(row.id);
                    }
                    // this.desactiveMedio(row.id)
                } else {
                    this.programaContactosService.active(row.id).subscribe(res => {
                        this.swal.showAlert(res['message'], 'success');
                        this.sending = -1;
                        this.getAll();
                    }, error => {
                        this.swal.showErrorAlert(error.error.message);
                    })
                }
            }
        });
    }


    getRowHeight(row: any) {
        return row.height;
    }

    desactiveMedio(id) {
        this.programaContactosService.desactive(id).subscribe(res => {
            this.swal.showAlert(res['message'], 'success');
            this.sending = -1;
            this.getAll();
        }, error => {
            this.swal.showErrorAlert(error.error.message);
        })
    }

    openEmailDialog(row) {
        this.dialog.open(EmailEditComponent, {
            data: row,
            width: '700px'
        }).afterClosed().pipe(take(1)).subscribe(res => {
            if (res) {
                if (res.change) {
                    this.emailChange.emit(true);
                }
            }
            this.desactiveMedio(row.id);
        });
    }

}
