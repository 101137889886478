import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { DemoMaterialModule } from './shared/demo.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SharedModule } from './shared/shared.module';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { TimingInterceptor } from './core/interceptors/timing.interceptor';
import { PipeModule } from './core/pipes/pipe.module';
import { ComponentsModule } from './components/components.module';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SearchPipe } from './core/pipes/search.pipe';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { AccordionModule } from './core/directives/accordion.module';
import { ImageModule } from './shared/components/image/image.module';
import { GlobalErrorHandler } from './core/providers/global-error-handler.provider';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PAGINATION_LABELS } from './core/providers/pagination-labels.provider';

registerLocaleData(es);

@NgModule({
    declarations: [
        AppComponent,
        AuthLayoutComponent,
    ],
    imports: [
        ImageModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        AccordionModule,
        RouterModule.forRoot(AppRoutes, { scrollPositionRestoration: 'enabled' }),
        FormsModule,
        ReactiveFormsModule,
        MatSidenavModule,
        MatInputModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatToolbarModule,
        MatTabsModule,
        MatCheckboxModule,
        MatProgressBarModule,
        MatSelectModule,
        MatCardModule,
        MatBadgeModule,
        DemoMaterialModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        PipeModule,
        ComponentsModule,
        LazyLoadImageModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        TranslateService,
        { provide: LOCALE_ID, useValue: "es-ES" },
        { 
          provide: MAT_DIALOG_DEFAULT_OPTIONS, 
          useValue: { disableClose: true, autoFocus: false, hasBackdrop: true } 
        },
        { 
          provide: ErrorHandler,
          useClass: GlobalErrorHandler 
        },
        { 
          provide: MAT_DATE_LOCALE, 
          useValue: 'es-ES' 
        },
        { 
          provide: MatPaginatorIntl, 
          useClass: PAGINATION_LABELS 
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TimingInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true
        },
        DatePipe,
        SearchPipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
