<div class="flex flex-row flex-wrap justify-start items-center content-center">
  <mat-form-field class="px-4 w-full" [appearance]="apparience">
      <mat-label>{{label}}</mat-label>
      <mat-chip-grid #chipList>
          <mat-chip-row *ngFor="let et of etiquetas" selectable removable
              (removed)="remove(et.label)">
              {{ et.label | titlecase }}
              <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input 
              placeholder="Agregar etiqueta" autocomplete="off" #labelInput [formControl]="labelForm"
              [matAutocomplete]="autoLabel" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addChipAdded($event)"
          >
      </mat-chip-grid>
      <mat-autocomplete 
          #autoLabel="matAutocomplete" 
          autoActiveFirstOption 
          (optionSelected)="selected($event)"
          position="below">
           <mat-option *ngFor="let label of filteredLabels | async" [value]="label">
              {{ label.label | titlecase }}
          </mat-option>
      </mat-autocomplete>
  </mat-form-field>
  
</div>
