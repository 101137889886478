import { Component, OnInit, OnDestroy } from '@angular/core';
import {DatePipe} from '@angular/common';
import * as echarts from 'echarts';

@Component({
  selector: 'speed-network',
  templateUrl: './speed-network.component.html',
  styleUrls: ['./speed-network.component.scss']
})
export class SpeedNetworkComponent implements OnInit, OnDestroy {
  times: any;
  interval: number | undefined;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.graficoNetwork();
  }

  graficoNetwork() {
    type eChartsOption = echarts.EChartsOption
    const chartDom = document.getElementById('network');
    const myChart = echarts.init(chartDom);
    let option: eChartsOption;

    interface DataItem {
      name: string;
      value: [string, number];
    }

    let valorX;
    let time: any;
    let acumTime = 1.35;
    this.times = this.datePipe.transform((new Date()).getTime(), 'h:mm:ss');
    time = this.times;
    const data: DataItem[] = [];

    function networkConnexion(): DataItem {
      const imageAddr = 'http://www.tranquilmusic.ca/images/cats/Cat2.JPG' + '?n=' + Math.random();
      let startTime;
      let endTimes;
      const downloadSize = 5616998;
      const download = new Image();
      download.onload = function () {
        endTimes = (new Date()).getTime();
        valorX = miFunction();
      }
      startTime = (new Date()).getTime();
      download.src = imageAddr;
      const miFunction = function () {
        const duration = (endTimes - startTime) / 1000;
        const bitsLoaded = downloadSize * 8;
        const speedBps: any = (bitsLoaded / duration).toFixed(2);
        const speedKbps: any = (speedBps / 1024).toFixed(2);
        const speedMbps: any = (speedKbps / 1024).toFixed(2);
        return speedMbps;
      }
      acumTime = acumTime + 0.01;
      return {name: `${time}`, value: [`${time}`, valorX ? valorX : acumTime]};
    }

    data.push(networkConnexion());
    option = {
      tooltip: {
        trigger: 'axis',
        formatter: function (params: any) {
          params = params[0];
          return (
              time +
              ' : ' +
              params.value[1] +
              ' Mbps'
          );
        },
        axisPointer: {
          animation: false
        }
      },
      xAxis: {
        name: 'h:mm:ss',
        type: 'category',
        splitLine: {
          show: false
        }
      },
      yAxis: {
        name: 'Mbps',
        type: 'value',
        boundaryGap: [0, '100%'],
        splitLine: {
          show: true
        }
      },
      series: [
        {
          name: 'Fake Data',
          type: 'line',
          showSymbol: false,
          data: data
        }
      ]
    };

    this.interval = window.setInterval(() => {
      this.times = this.datePipe.transform((new Date()).getTime(), 'h:mm:ss');
      time = this.times;
      data.push(networkConnexion());
      myChart.setOption<echarts.EChartsOption>({
        series: [
          {
            data: data
          }
        ]
      });
    }, 1000);
    myChart.setOption(option);
  }

  close() {
    window.clearInterval(this.interval);
  }

  ngOnDestroy() {
    window.clearInterval(this.interval);
  }

}
