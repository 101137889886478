<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form #socialNetworkForm="ngForm">
        <div class="grid gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Red social</mat-label>
                <mat-select [(ngModel)]="socialNetwork.idTipoRed" name="idTipoRed" required>
                    <mat-option>Ninguno</mat-option>
                    <mat-option *ngFor="let sn of socialNetworks" [value]="sn.id">{{sn.name}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Url</mat-label>
                <input matInput type="text" autocomplete="off" [(ngModel)]="socialNetwork.red" name="red" required>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" (click)="cancel()" type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="socialNetworkForm.invalid"
        label="Guardar"></adp-button-icon>
</div>