import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PressReleaseService} from 'src/app/core/services/api/press-release.service';
import {AsignarPlanMediosFormComponent} from '../form/asignar-plan-medios-form.component';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Note } from 'src/app/core/contracts/models/note';

@Component({
    selector: 'app-asignar-plan-medios-list',
    templateUrl: './asignar-plan-medios-list.component.html',
    styleUrls: ['./asignar-plan-medios-list.component.scss']
})
export class AsignarPlanMediosListComponent implements OnInit {

    @Input() id: any;
    rows: any[] = [];
    temp: any[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    addPermission = this.auth.isPermit('nota-prensa.plan-medio.add');
    deletePermission = this.auth.isPermit('nota-prensa.plan-medio.delete');
    constructor(
        public dialog: MatDialog,
        private pressReleaseService: PressReleaseService,
        private auth: AuthService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.pressReleaseService.getId(this.id).subscribe((response: Note) => {
            this.rows = response.planMedios;
        })
    }

    add() {
        this.openDialog();
    }

    edit(row: any) {
        this.openDialog(row);
    }

    openDialog(row?: any) {
        const dialogRef = this.dialog.open(AsignarPlanMediosFormComponent, {
            data: {row, id: Number(this.id)},
            width: '800px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAll();
            }
        });
    }

    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.pressReleaseService.dissociatePlanMedio(this.id, id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }
}
