<div style="margin-bottom: 10px;">
    <div class="px-4 my-4 flex flex-row justify-between items-baseline content-baseline">
        <h3>Medio</h3>
        <button type="button" mat-raised-button *ngIf="addPermission" color="primary" (click)="openDialog()">
            Agregar medio
        </button>
    </div>
    
    <ngx-datatable class="material min" [messages]="messages" [columnMode]="'force'" [headerHeight]="45" [footerHeight]="45"
        [rowHeight]="getRowHeight" [scrollbarV]="true" [scrollbarH]="true" [rows]="rows">

        <ngx-datatable-column name="#" [width]="10" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Medio" prop="programa.medio.nombre">
        </ngx-datatable-column>
        <ngx-datatable-column name="Programa / Sección" prop="programa.nombre">
        </ngx-datatable-column>
        <ngx-datatable-column name="Influencia" prop="tipoInfluencia">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngIf="value === 1">Externo</div>
                <div *ngIf="value === 2">Interno</div>
                <div></div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Cargo" prop="cargos">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngFor="let item of value">{{item.descripcion}}</div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Plataforma" prop="medioPlataformas">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div *ngFor="let item of value">
                    {{item.plataforma_clasificacion.plataforma.descripcion}} >
                    {{item.plataforma_clasificacion.descripcion}}: {{item.valor}}
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Observación" prop="observacion"></ngx-datatable-column>
        <ngx-datatable-column name="Acciones" prop="id" [width]="160" [canAutoResize]="false" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button mat-stroked-button mat-button-sm color="primary" [disabled]="row.activo === 0"
                    *ngIf="editPermission" type="button" class="me-2"
                    (click)="edit(row)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-stroked-button mat-button-sm  [disabled]="row.activo === 0" class="me-2"
                    *ngIf="deletePermission" type="button" (click)="delete(value)">
                    <mat-icon>delete</mat-icon>
                </button>

                <button mat-stroked-button mat-button-sm [color]="row.activo === 0?'primary':'warn'"
                    [matTooltip]="row.activo === 1?'Desactivar':'Activar'" [disabled]="sending === row.id"
                    *ngIf="togglePermission" type="button" (click)="desactivate(row)">
                    <mat-icon class="relative -left-[6px]" iconPositionEnd [class.animate-spin]="sending === row.id" *ngIf="sending === row.id;else noSend">
                        autorenew
                    </mat-icon> 
                    <ng-template #noSend>
                        <mat-icon class="relative -left-[6px]">power_settings_new</mat-icon>
                    </ng-template>

                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>