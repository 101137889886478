<aside #sidemenu class="top-0 left-0 h-screen overflow-x-hidden max-w-screen bg-white flex flex-col" aria-label="Sidebar">
  <div class="sidebar-logo text-center">
    <img [src]="logo" class="w-36" alt="ADP Logo">
  </div>
  <div class="w-full grid text-center my-3">
    <adp-sidebar-avatar [image]="user?.photo" [label]="user?.name"></adp-sidebar-avatar>
  </div>
  <hr class="m-0 border-adp-primary border-t-2">
  <div class="px-0 bg-white overflow-auto grow pt-2">
     <adp-sidebar-links></adp-sidebar-links>
  </div>
  <hr class="mt-3 border-b">
  <div class="h-8 p-1 text-base text-center text-gray-400">
    <span>ADP {{version}}</span>
  </div>
</aside>
