<div mat-dialog-title class="!text-base">
        Personas Seleccionadas
</div>
<div class="no-platform-div" *ngIf="noPlatformRow.length">
    <mat-icon>warning</mat-icon> <span>Hay Medios sin Plataformas Vinculadas</span>
</div>
<mat-dialog-content>
    <div class="mat-elevation-z2">
        <div class="detail-container" *ngIf="dataSource.data.length;else noData">
            <table mat-table [dataSource]="dataSource" style="width: 100%;" matSort>
                <ng-container matColumnDef="select" sticky>
                    <th mat-header-cell *matHeaderCellDef>
                        #
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox
                            [disabled]="noPlatformsRow(row)"
                            (change)="$event ? selection.toggle(row) : null" 
                            [checked]="selection.isSelected(row)"
                            [aria-label]="'Row-checkbox'">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="contactName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Periodista</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.contactName}}
                    </td>
                </ng-container>

                <ng-container *ngIf="displayedColumns.includes('medioName')" matColumnDef="medioName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Medio:</strong> programa</th>
                    <td mat-cell *matCellDef="let row">
                        <strong>{{row.programa_contacto.programa.medio.nombre}}:</strong><br>
                        <i>{{row.programa_contacto.programa.nombre}}</i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{noPlatformsRow: noPlatformsRow(row)}"></tr>
            </table>
        </div>
        <ng-template #noData>
            <div style="text-align: center;">Sin Resultados</div>
        </ng-template>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" type="button" mat-dialog-close
            style="margin-right: 5px;">Cancelar</button>
    <button mat-raised-button color="primary" mat-dialog-close (click)="onSubmit()" type="button" [disabled]="selection.selected.length === 0">
        Generar
    </button>
</mat-dialog-actions>
