<cdk-accordion>
  <cdk-accordion-item
    *ngFor="let route of links; index as $index;"
    #accordionItem="cdkAccordionItem"
    role="menubar"
    tabindex="0"
    [attr.id]="'accordion-header-' + $index"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.aria-controls]="'accordion-body-' + $index"
  >
    <a *ngIf="route.type === 'link'" [routerLink]="[route.link]" routerLinkActive="active" matRipple 
      class="flex items-center text-base h-[48px] px-4 text-gray-500 hover:text-gray-800 hover:bg-gray-100 group">
      <i class="material-icons">{{route.icon}}</i>
      <span class="ml-2">{{ route.label }}</span>
    </a>

    <ng-container *ngIf="route.type === 'tree'">
      <a matRipple
        class="flex items-center w-full text-base h-[48px] px-4 text-gray-500 hover:text-gray-800 hover:bg-gray-200 group"
        [ngClass]="{'bg-gray-50': accordionItem.expanded}"
        aria-expanded="false"
        (click)="accordionItem.toggle()">
        <i class="material-icons">{{route.icon}}</i>
        <span class="flex-1 ml-2 text-left whitespace-nowrap"> {{ route.label }} </span>
        <i [class.rotate-180]="accordionItem.expanded" class="material-icons transition-transform duration-500">expand_more</i>
      </a>
      <ul matRipple
        class="overflow-y-hidden transition-[max-height] duration-300 ease-in-out origin-top !my-0 list-none px-0"
        [attr.id]="'accordion-body-' + $index"
        [attr.aria-labelledby]="'accordion-header-' + $index"
        [ngClass]="accordionItem.expanded ? 'bg-gray-50 max-h-550' : 'max-h-0'">
        <li *ngFor="let item of route.tree">
          <a [routerLink]="[item.link]" routerLinkActive="active"
            class="flex items-center w-full py-2.5 text-gray-500 hover:text-gray-800 text-base pl-[53px] group hover:bg-gray-200">
            {{ item.label }}
          </a>
        </li>
      </ul>
    </ng-container>
  </cdk-accordion-item>
</cdk-accordion>