<div mat-dialog-title>
    <h3>
        Titulo
        <!-- <span *ngIf="data.stage !== 'isNew'">: {{ editContactName }}</span> -->
    </h3>
    <mat-divider></mat-divider>
    <mat-progress-bar *ngIf="loading_form" mode="indeterminate"></mat-progress-bar>
</div>

<mat-dialog-content>
    <!-- <ng-container *ngIf="changeContact$ | async"></ng-container>
    <ng-container *ngIf="changeProgram$ | async"></ng-container> -->
    <form [formGroup]="myForm">
        <div class="flex flex-row flex-wrap justify-start items-center content-center">
            <search-box style="width: 100%;" placeholder="Buscar Contactos" search_placeholder="Buscar Contactos"
                observer_diccionary="contact" (selectedId)="selectedId($event)">
            </search-box>
        </div>
        <div class="flex flex-row flex-wrap justify-start items-center content-center">
            <mat-form-field class="px-4 w-full">
                <mat-label><b>Medio:</b> programa</mat-label>
                <mat-select disableOptionCentering formControlName="contact_program" required>
                    <mat-option>
                        <ngx-mat-select-search [formControl]="contact_program_filter" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                        *ngFor="let contact_program of contact_program_select | search: contact_program_filter.value:['label']"
                        [value]="contact_program.programa_id">
                        <b>{{contact_program.label}}</b>
                    </mat-option>

                </mat-select>
                <mat-hint *ngIf="medio_programa_hint" align="end">No existen Programas vinculados al Contacto
                    seleccionado</mat-hint>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="px-4 w-full">
                <mat-label>Plataformas</mat-label>
                <mat-select disableOptionCentering multiple formControlName="media_platforms" placeholder="Seleccione">
                    <mat-select-trigger>
                        <mat-chip-listbox class="multi">
                            <mat-basic-chip class="chipName" *ngFor="let media_platform of media_platform_select"
                                [removable]="true">
                                <mat-icon matChipRemove>close</mat-icon>
                                <span>{{media_platform.label}}</span>
                            </mat-basic-chip>
                        </mat-chip-listbox>
                    </mat-select-trigger>
                    <mat-option *ngIf="media_platform_select.length > 4">
                        <ngx-mat-select-search [formControl]="platform_filter" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                        *ngFor="let media_platform of media_platform_select| search: platform_filter.value:['plat','descrip','val']"
                        [value]="media_platform.id">
                        {{media_platform.label}}
                    </mat-option>
                </mat-select>

            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="px-4 w-full">
                <mat-label>Voceros</mat-label>
                <mat-select disableOptionCentering multiple formControlName="spokesmens" placeholder="Seleccione">
                    <mat-option *ngFor="let spokesmen of spokesmens_select" [value]="spokesmen.id">
                        {{spokesmen.label}}
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="voceros_hint" align="end">No hay Voceros vinculados a la Campaña</mat-hint>
            </mat-form-field>
        </div>
        <div class="flex flex-row flex-wrap justify-start items-center content-center">
            <mat-form-field class="px-4 w-full sm:w-1/3">
                <mat-label>Tipo de Nota</mat-label>
                <mat-select formControlName="note_type">
                    <mat-option *ngFor="let note of note_types" [value]="note.id">{{note.name}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="px-4 w-full sm:w-1/3">
                <mat-label>Etapa</mat-label>
                <mat-select formControlName="phase_type">
                    <mat-option *ngFor="let stage of phase_types" [value]="stage.id">{{stage.name}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="px-4 w-full sm:w-1/3">
                <mat-label>Tier</mat-label>
                <mat-select formControlName="tier_type">
                    <mat-option *ngFor="let tier of tier_types" [value]="tier.id">{{tier.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="flex flex-row flex-wrap justify-start items-center content-center" style="margin-top: 16px;">
            <mat-form-field class="px-4 w-full sm:w-1/3" appearance="fill">
                <mat-label>Muestras registradas</mat-label>
                <input matInput type="number" autocomplete="off" min="0" formControlName="registered_samples">
                <mat-error>
                    <span *ngIf="myForm.get('registered_samples').errors?.['min']">Solo positivos</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="px-4 w-full sm:w-1/3" appearance="fill">
                <mat-label>Muestras enviadas</mat-label>
                <input matInput type="number" autocomplete="off" min="0" [max]="myForm.get('registered_samples').value"
                    formControlName="sent_samples">
                <mat-error>
                    <span *ngIf="myForm.get('sent_samples').errors?.['min']">Solo positivos</span>
                    <span *ngIf="myForm.get('sent_samples').errors?.['maximumExceeded']">
                        No puede ser mayor al número de muestras Registradas</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="px-4 w-full sm:w-1/3" appearance="fill">
                <mat-label>Muestras verificadas</mat-label>
                <input matInput type="number" autocomplete="off" min="0" [max]="myForm.get('sent_samples').value"
                    formControlName="verified_samples">
                <mat-error>
                    <span *ngIf="myForm.get('verified_samples').errors?.['min']">Solo positivos</span>
                    <span *ngIf="myForm.get('verified_samples').errors?.['maximumExceeded']">
                        No puede ser mayor al número de muestras Enviadas</span>
                </mat-error>
            </mat-form-field>

        </div>
        <div class="flex flex-row flex-wrap justify-start items-center content-center">
            <mat-form-field class="px-4 w-full">
                <mat-label>Bitácora</mat-label>
                <textarea matInput placeholder="Resumen de la Bitácora" formControlName="observation" autocomplete="off"
                    cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5">
                    </textarea>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" type="button" mat-dialog-close style="margin-right: 5px;">
        Cancelar
    </button>
    <button mat-raised-button color="primary" type="button" (click)="submitForm()" [disabled]="myForm.invalid">
        <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        Guardar
    </button>
</mat-dialog-actions>