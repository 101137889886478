<div style="margin-bottom: 10px;">
    <div class="flex flex-row flex-wrap gap-x-4 gap-y-2">
        <form [formGroup]="myForm" class="w-full sm:w-2/5">
            <mat-form-field class="w-full">
                <mat-label>Agente</mat-label>
                <mat-select disableOptionCentering formControlName="agente">
                    <mat-option>
                        <ngx-mat-select-search formControlName="filter" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let c of users| search: myForm.get('filter').value:['name']"
                        [value]="c.id">{{c.name}}</mat-option>

                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
        </form>
        <adp-button-icon class="relative sm:top-3" (clicked)="add()" color="primary" [disabled]="myForm.invalid"
            [loading]="buttonSubmit" label="Agregar" [icon]="'add'"></adp-button-icon>
    </div>


    <ngx-datatable class="material min" [messages]="messages" [columnMode]="'force'" [headerHeight]="45"
        [footerHeight]="45" [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true" [rows]="rows">

        <ngx-datatable-column name="#" [width]="10" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Agente" prop="user.name">
        </ngx-datatable-column>
        <ngx-datatable-column name="Acciones" prop="id" *ngIf="hasPermission" [width]="200" [canAutoResize]="false"
            [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">

                <button mat-stroked-button mat-button-sm type="button" (click)="delete(value)">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>