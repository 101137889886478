<div style="margin-bottom: 10px;">
    <div class="px-4 flex flex-row flex-wrap justify-between items-center content-center">
        <h3 style="font-size: 16px;">Cliente</h3>
        <button type="button" mat-raised-button mat-button-sm *ngIf="addPermission"
            color="primary" class="me-4 mb-4" (click)="openDialog()">
            Agregar cliente
        </button>
    </div>


    <ngx-datatable class="material min" [messages]="messages" [columnMode]="'force'" [headerHeight]="45"
        [footerHeight]="45" [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true" [rows]="rows">

        <ngx-datatable-column name="#" [width]="10" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Cliente" prop="cliente.nombreComercial">
        </ngx-datatable-column>
        <ngx-datatable-column name="Cargo" prop="cargo">
        </ngx-datatable-column>
        <ngx-datatable-column name="Acciones" prop="id" [canAutoResize]="false" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button mat-stroked-button mat-button-sm color="primary"
                    *ngIf="editPermission" type="button" class="me-2"
                    (click)="edit(row)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-stroked-button mat-button-sm 
                    *ngIf="deletePermission" type="button" (click)="delete(value)">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>