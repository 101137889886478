import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { format, addDays, differenceInDays } from 'date-fns';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CampaignsService } from 'src/app/core/services/api/campaigns.service';
import { TimingService } from 'src/app/core/services/timing.service';
import { ConnectionService } from 'src/app/core/services/PWA/connection.service';
import { MatDialog } from '@angular/material/dialog';
import { SpeedNetworkComponent } from 'src/app/components/speed-network/speed-network.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /** Speed Test */  //! Mejorar implementación
  public timming_ms: string = '0 ms';
  public timming_color: string = 'green';
  private timming_subscription: Subscription;
  public connectionStatus: Observable<boolean> = this.connection.onlineStatus$;
  // public connectionIcon: Observable<string> = this.connection.onlineStatus$.pipe(map(r => r ? 'speed' : 'wifi_off'));
  campaignList = [];
  today: number = Date.now();
  
  @Output() toggleSidebarLinks = new EventEmitter<void>();
  @Output() showNotifications = new EventEmitter<void>();
  
  constructor(
    private dialog: MatDialog,
    private auth: AuthService, 
    private campaign: CampaignsService, 
    private timingService: TimingService, 
    private connection: ConnectionService,) { }

  ngOnInit(): void {
    // campañas
    const send = {
      fechaInicio: format(this.today, 'yyyy-MM-dd'),
      fechaFin: format(addDays(this.today, 7), 'yyyy-MM-dd')
    }

    this.campaign.getCampaignByDate(send).subscribe(res => {
      this.campaignList = res['campaigns'].map(cm => {
        const end = new Date(cm.fechaFin)
        cm['isToday'] = differenceInDays(end, this.today)
        cm['open'] = false
        return cm
      }).sort((a, b) => a.isToday - b.isToday)
    })

    this.timming_subscription = this.timingService
      .getAverageResponseRate()
      .subscribe(time => {
        this.timming_ms = time.toString().concat(' Kbps');
        this.timming_color = this.timingService.getStatusRate(parseInt(time))
      });
  }

  public toggleSidebar() {
    this.toggleSidebarLinks.emit();
  }

  public openDialogNetwork() {
    this.dialog.open(SpeedNetworkComponent, {
        width: '600px'
    });
  }

  public logout() {
    this.auth.logout()
  }

  ngOnDestroy() {
    this.timming_subscription.unsubscribe();
  }
  
  public openNotification(){
    this.showNotifications.emit();
  }
}
