import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../material/material.module';
import {ListSelectedPeopleComponent} from './list-selected-people.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
@NgModule({
    declarations: [ListSelectedPeopleComponent],
    imports: [
        CommonModule,
        MaterialModule,
        MatTableModule,
        MatSortModule
    ]
})
export class ListSelectedPeopleModule {
}
