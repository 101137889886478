import {Component, Input, OnInit} from '@angular/core';
import {Validators, FormBuilder, FormControl} from '@angular/forms';
import {CampaignsService} from 'src/app/core/services/api/campaigns.service';
import {UserService} from 'src/app/core/services/api/user.service';
import {BehaviorSubject} from 'rxjs';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { AuthService } from 'src/app/core/services/auth/auth.service';


@Component({
    selector: 'app-asignar-campana',
    templateUrl: './asignar-campana.component.html',
    styleUrls: ['./asignar-campana.component.scss']
})
export class AsignarCampanaComponent implements OnInit {

    @Input() id: any;
    @Input() idCliente: any;
    rows: any[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    users: any = [];
    myForm = this.formBuilder.group({
        agente: new FormControl<string|null>(null,[Validators.required]),
        filter:  new FormControl<string|null>(null),
    });

    buttonSubmit = false

    list = new BehaviorSubject<any>([]);
    list$ = this.list.asObservable();

    hasPermission = this.auth.isPermit('campaign.agente.delete');
    
    constructor(
        private auth: AuthService,
        private formBuilder: FormBuilder,
        public userService: UserService,
        private campaignsService: CampaignsService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.userService.legacyGetAll().subscribe((response: any) => {
            const data = response.users;
            this.users = data;
        });

        this.getAll();
    }

    add() {
        const agent = this.myForm.controls.agente.value;
        const send = {
            campaign_id: this.id,
            user_id: agent
        }
        this.buttonSubmit = true;
        this.campaignsService.createCampaignAgente(send).subscribe((response: any) => {
            this.buttonSubmit = false;

            const user = this.users.find(s => s.id == agent);
            response['campaignResponsable']['user'] = user;
            const arr = [...this.rows].concat([response['campaignResponsable']]);
            this.rows = arr;
            this.list.next(arr);
            this.myForm.controls.agente.reset();
            this.swal.showToast(response.message, 'success');
        }, error => {
            if (error.error.errors) {
                if (error.error.errors.user_id) {
                    this.swal.showToast(error.error.errors.user_id[0], 'error');
                }
            }
            this.myForm.controls.agente.reset();
            this.buttonSubmit = false;
        });
    }

    getAll() {
        this.campaignsService.getAllCampaignAgente(this.id).subscribe((response: any) => {
            const {campaignResponsables} = response;
            this.rows = campaignResponsables;
            this.list.next(this.rows);
        });
    }


    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.campaignsService.deleteCampaignAgente(id).subscribe((response: any) => {
                    const arr = [...this.rows].filter(r => r.id != id);
                    this.rows = arr;
                    this.list.next(arr);
                    this.swal.showToast(response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });

    }

}
