import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendEmailDialogComponent } from './send-email-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material/material.module';
import { QuillModule } from 'ngx-quill'
import { PipeModule } from 'src/app/core/pipes/pipe.module';
import { SearchPipe } from 'src/app/core/pipes/search.pipe';
@NgModule({
  declarations: [
    SendEmailDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MaterialModule,
    QuillModule.forRoot(),
    PipeModule
  ],
  exports: [
    SendEmailDialogComponent
  ],
  providers: [
    SearchPipe
  ]
})
export class SendEmailDialogModule { }
