<mat-toolbar class="flex bg-white">
  <button mat-icon-button (click)="toggleSidebar()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="flex-1"></span>
  <!-- <button (click)="openDialogNetwork()" mat-icon-button matTooltip="Solución de problemas de red" matTooltipPosition="above">
      <mat-icon>network_check</mat-icon>
  </button> -->
  <button mat-icon-button disabled>
    <mat-icon [matTooltip]="(connectionStatus | async) ? timming_ms : 'Sin Conexión'" class="speed-icon"
      [ngClass]=" (connectionStatus | async) ? timming_color : null" matTooltipPosition="above">
      {{(connectionStatus | async) ? 'speed' : 'wifi_off'}}
    </mat-icon>
  </button>
  <button (click)="openNotification()" mat-icon-button>
    <mat-icon [matBadge]="campaignList.length" [matBadgeHidden]="campaignList.length === 0">notifications</mat-icon>
  </button>
  <button mat-icon-button [matMenuTriggerFor]="user">
    <mat-icon>person</mat-icon>
  </button>
</mat-toolbar>

<mat-menu #user="matMenu">
  <a [routerLink]="['/profile']" mat-menu-item>
    <mat-icon>account_box</mat-icon>
    Mi perfil
    </a>
    <a href="javascript:void(0)" (click)="logout()" mat-menu-item>
      <mat-icon>exit_to_app</mat-icon>
      Cerrar sesión
    </a>
</mat-menu>
