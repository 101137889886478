import { Injectable, inject } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild {
  private auth = inject(AuthService);
  private router = inject(Router);

  canActivate(): Observable<boolean | UrlTree> {
    return this.auth.isLoggedIn$.pipe(
      map(isLoggedIn => isLoggedIn || this.router.createUrlTree(['/authentication/login']))
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    if (route.data['permission'] === 'all' || this.auth.isPermit(route.data['permission'])) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }

}
