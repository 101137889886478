<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form #emailForm="ngForm" class="grid gap-y-2">
        <mat-form-field class="w-full">
            <mat-label>Tipo</mat-label>
            <mat-select [(ngModel)]="email.idTipoEmail" name="idTipoEmail" required>
                <mat-option>Ninguno</mat-option>
                <mat-option *ngFor="let e of emails" [value]="e.id">{{e.name}}</mat-option>
            </mat-select>
            <mat-error>
                Completa este campo.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Correo</mat-label>
            <input matInput required type="email" [(ngModel)]="email.email" name="email" autocomplete="off"
                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" #emailModel="ngModel">
            <mat-error *ngIf="emailModel.errors?.['required']">
                Completa este campo.
            </mat-error>
            <mat-error *ngIf="emailModel.errors?.['pattern']">
                Introduzca un correo valido.
            </mat-error>
        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" (click)="cancel()" type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="emailForm.invalid"
        label="Agregar"></adp-button-icon>
</div>