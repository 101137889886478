import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Api } from '../../contracts/classes/api.class';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class PressReleaseService extends Api {

  private auth = inject(AuthService);

  getAll() {
    return this.http.get(`${this.url}/notaPrensas`);
  }
  getSelect() {
    return this.http.get(`${this.url}/notaPrensas/select`);
  }

  getId(id: number) {
    return this.http.get(`${this.url}/notaPrensas/${id}`).pipe(
      map((response: any) => {
        return response.notaPrensa;
      })
    );
  }

  associatePlanMedio(id: number, idPlanMedio: number) {
    return this.http.get(`${this.url}/notaPrensas/data/${id}/associatePlanMedio/${idPlanMedio}`);
  }

  dissociatePlanMedio(id: number, idPlanMedio: number) {
    return this.http.get(`${this.url}/notaPrensas/data/${id}/dissociatePlanMedio/${idPlanMedio}`);
  }

  create(data: any) {
    return this.http.post(`${this.url}/notaPrensas`, data);
  }

  edit(data: any) {
    return this.http.post(`${this.url}/notaPrensas/${data.get('id')}?_method=PUT`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/notaPrensas/${id}`);
  }

  display(id: number) {
    return `${this.url}/notaPrensas/data/displayFile/${id}?token=${this.auth.getTokenValue()}`;
  }

  downloadFile(id: number) {
    return this.http.get(`${this.url}/notaPrensas/data/downloadFile/${id}`, {
      responseType: 'blob'
    });
  }

}
