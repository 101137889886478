import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {PeopleService} from '../../core/services/api/people.service';
import {take} from 'rxjs/operators';

import { MediaqueryUtils } from 'core/utils/mediaquery.util';
import { PersonDtoSelected } from 'src/app/core/contracts/models/person_dto.model';

@Component({
  selector: 'app-list-selected-people',
  templateUrl: './list-selected-people.component.html',
  styleUrls: ['./list-selected-people.component.scss']
})
export class ListSelectedPeopleComponent implements OnInit {
    isDesktop: boolean;
    private mediaService = new MediaqueryUtils('(min-width: 600px)');

    dataSource = new MatTableDataSource<PersonDtoSelected>();
    displayedColumns: string[] = ['select', 'contact', 'medio']

    selection = new SelectionModel<PersonDtoSelected>(true, []);
    noPlatformRow: number[] = [];
    @ViewChild(MatSort, {static: false}) set content2(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<ListSelectedPeopleComponent>,
        private peopleService: PeopleService,
        @Inject(MAT_DIALOG_DATA) public data: PersonDtoSelected[]
    ) { }

    public ngOnInit(): void {
        this.mediaService.match$.pipe(take(1)).subscribe(value => {
        this.isDesktop = value
        if (value) {
            this.displayedColumns = ['select', 'contactName', 'medioName']
        } else {
            this.displayedColumns = ['select', 'contactName'] 
        }
        });
        this.dataSource.data = this.data
        this.dataSource.data.forEach(row => {
            if(row.programa_contacto.idsMedioPlataforma != "") this.selection.select(row)
            else this.noPlatformRow.push(row.id);
        }); 
    }

    public onSubmit() {
        this.peopleService.addPeople(this.selection.selected);
        this.router.navigate(['/database/media-plan/create/']);
    }

    public noPlatformsRow( row:PersonDtoSelected ): boolean {
        return this.noPlatformRow.includes(row.id);
    }
}
