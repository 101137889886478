import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimingService {

  private readonly n_prom: number = 3;  //numeros que serán promediados
  private readonly n_length: number = 5;   

  private responseKBps: number[] = [];
  private averageResponseRateSubject$: Subject<string> = new Subject<string>();

  public getAverageResponseRate(): Subject<string> {
    return this.averageResponseRateSubject$;
  }

  public addResponseRate( bytes: number, miliseconds: number ) {

    // No se hace el calculo en caso se consuma una api que no retorne el Header: Content-Length
    if( isNaN(bytes) || isNaN(miliseconds) ) return 
    const loadedKBs = bytes * 8 / 1024;
    const timeDuration = miliseconds / 1000;
    const transferRateMbps = loadedKBs / timeDuration;
    
    this.responseKBps.push(transferRateMbps);
    if (this.responseKBps.length > this.n_length) {
        // Se elimina el rate más antiguo si hay más que los permitidos
        this.responseKBps.shift(); 
    }

    const averageResponseRate = this.calculateAverageResponseRate();

    this.averageResponseRateSubject$.next(averageResponseRate);
  }

  public getStatusRate( rate: number ):string {
    if( rate > 15 ) return 'good';
    else if( rate > 2 ) return 'moderate';
    else return 'bad';
  }

  private calculateAverageResponseRate(): string {

    const aux_array = this.responseKBps;
    // Ordena el array de forma descendente
    const sortedArray = aux_array.sort( (a, b) => a - b );

    // Obtén los N primeros números del array ordenado
    const selectedArray = sortedArray.slice( 0, this.n_prom );
    
    // Calcula la suma de los N números
    const sum = selectedArray.reduce( (acc, time) => acc + time, 0 );
    // Retorna el calculo del promedio dividiendo la suma entre el total
    return  ( sum / selectedArray.length ).toFixed(1);
  }
}
