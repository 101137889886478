import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import {MatDialog} from '@angular/material/dialog';
import {SocialNetworksFormComponent} from '../form/social-networks-form.component';
import { Network } from 'src/app/core/contracts/models/network';


@Component({
    selector: 'app-social-networks-list',
    templateUrl: './social-networks-list.component.html',
    styleUrls: ['./social-networks-list.component.scss']
})
export class SocialNetworksListComponent implements OnInit {


    @Output() onNetworks = new EventEmitter<any>();
    @Input() data: any[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    constructor(
        public dialog: MatDialog,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
    }

    openDialog(row?: Network) {
        const dialogRef = this.dialog.open(SocialNetworksFormComponent, {
            data: row,
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addSocialNetwork(result);
            }
        });
    }

    addSocialNetwork(socialNetwork: Network) {
        const rows = [...this.data];
        rows.push(socialNetwork);
        this.data = rows;
        this.onNetworks.emit(rows);
    }

    editNetwork(row: Network, id: number) {
        const rows = [...this.data];
        rows.splice(id, 1);
        this.data = rows;
        this.openDialog(row);
        this.onNetworks.emit(rows);
    }

    deleteNetwork(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                const rows = [...this.data];
                rows.splice(id, 1);
                this.data = rows;
                this.onNetworks.emit(rows);
                this.swal.showTitleAlert('Eliminado', 'Registro eliminado con éxito', 'success');
            }
        });
    }

}
