import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CampaignsService} from 'src/app/core/services/api/campaigns.service';
import {take} from 'rxjs/operators';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import {AsignarVocerosFormComponent} from '../form/asignar-voceros-form.component';
import {BehaviorSubject} from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
    selector: 'app-asignar-voceros-list',
    templateUrl: './asignar-voceros-list.component.html',
    styleUrls: ['./asignar-voceros-list.component.scss']
})
export class AsignarVocerosListComponent implements OnInit {

    @Input() id: any;
    @Input() idCliente: any;
    rows: any[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    list = new BehaviorSubject<any>([]);
    list$ = this.list.asObservable();

    addPermission = this.auth.isPermit('campaign.vocero.add');
    editPermission = this.auth.isPermit('campaign.vocero.edit');
    deletePermission = this.auth.isPermit('campaign.vocero.delete');

    constructor(
        public dialog: MatDialog,
        private campaignsService: CampaignsService,
        private auth: AuthService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.campaignsService.getAllCampaignVoceros(this.id).subscribe((response: any) => {
            const {campaignVoceros} = response;
            this.rows = campaignVoceros;
            this.list.next(campaignVoceros);
        });
    }

    add() {
        this.openDialog();
    }

    edit(row: any) {
        this.openDialog(row);
    }

    openDialog(row?: any) {
        const dialogRef = this.dialog.open(AsignarVocerosFormComponent, {
            data: {row, id: Number(this.id), idCliente: this.idCliente},
            width: '800px'
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.getAll();
            }
        });
    }

    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.campaignsService.deleteCampaignVoceros(id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }
}
