import { Injectable } from '@angular/core';
import { Api } from '../../contracts/classes/api.class';
import { Observable, map } from 'rxjs';
import { BitacoraLogsFeed } from '../../contracts/models/bitacora.model';
import { ApiResult } from '../../contracts/types/api-response.type';

@Injectable({providedIn: 'root'})
export class BitacoraService extends Api {

  /**@deprecated */
  create(data) {
    return this.http.post(`${this.url}/bitacoras`, data);
  }

  /**@deprecated */
  get(id) {
    return this.http.get(`${this.url}/bitacoras${id}`);
  }

  /**
   * @version API.v2
   * @param dmp 
   */
  public getBitacoraLogsFeedByDmp(dmp: number): Observable<BitacoraLogsFeed> {
    return this.http
      .get<ApiResult<BitacoraLogsFeed>>(`${this.urlv2}/bitacoras/getBitacoraLogsByDmp/${dmp}`)
      .pipe(map(r => r.data));
  }
}
