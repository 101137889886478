import {Component, OnInit, Inject} from '@angular/core';

import {Option} from 'app/core/contracts/models/option';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DynamicSelectService} from 'src/app/core/services/api/dynamic-select.service';
import {FormBuilder,        FormControl,        Validators} from '@angular/forms';
import {codePhones} from 'app/shared/general/phonecodes';
import { Phone } from 'src/app/core/contracts/models/phone';

@Component({
    selector: 'app-types-of-phones-form',
    templateUrl: './types-of-phones-form.component.html',
    styleUrls: ['./types-of-phones-form.component.scss']
})
export class TypesOfPhonesFormComponent implements OnInit {
    
    regx = /\(?(^[0-9-+\s()#]*$)/

    phoneForm = this.formBuilder.group({
        idTipoTelefono:new FormControl<number|null>(null,[Validators.required]),
        telefono:new FormControl<string|null>(null,[Validators.required, Validators.maxLength(12), Validators.pattern(this.regx)]),
        country: new FormControl<string|null>(null),
        filter: new FormControl<string|null>(null),
    });

    phonesCode = codePhones
    phone: Phone = {
        idTipoTelefono: null,
        tipo_telefono: null,
        telefono: null
    };

    phones: Option[] = [];

    title = 'Agregar teléfono';

    selectedC: any;

    constructor(
        private formBuilder:FormBuilder,
        public dialogRef: MatDialogRef<TypesOfPhonesFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Phone,
        private dynamicSelectService: DynamicSelectService
    ) {
    }

    ngOnInit(): void {
        if (this.data) {
            this.title = 'Editar teléfono';
            this.phoneForm.controls.idTipoTelefono.setValue(this.data.tipo_telefono.id);
            this.phoneForm.controls.telefono.setValue(this.data.telefono);
            const num = this.data.telefono.split(' ');
            const cod = this.phonesCode.find(el => el.dial_code == num[0]);
            if (cod) {
                this.phoneForm.controls.country.setValue(cod.dial_code);
                this.selectedC = cod;
                this.phoneForm.controls.telefono.setValue(num.slice(1).join(' '));
            }
        } else {
            this.phoneForm.controls.country.setValue('+51');
            this.selectedC = this.phonesCode.find(el => el.dial_code == '+51');
        }
        this.dynamicSelectService.getListBySlug('telephone').subscribe((response: any) => {
            this.phones = response.tipoAtributos;
        });
    }


    onSubmit() {
        const dataPhone = this.phoneForm.value;
        this.phone.idTipoTelefono = dataPhone.idTipoTelefono;
        this.phone.tipo_telefono = this.phones.find(e => e.id === dataPhone.idTipoTelefono);
        this.phone.telefono = dataPhone.country ? dataPhone.country + ' ' + dataPhone.telefono : dataPhone.telefono;
        this.dialogRef.close({...this.phone});
    }

    cancel() {
        this.dialogRef.close(this.data);
    }

    selectCountry(c) {
        this.selectedC = c;
    }

    clear() {
        this.phoneForm.controls.country.setValue(null);
    }

    onlyNumber(e) {
        const input = this.phoneForm.controls.telefono.value;
        const key = e.keyCode || e.which;
        const character = String.fromCharCode(key);
        let inputValue;
        if (input) {
            inputValue = input + character;
        } else {
            inputValue = character;
        }
        return this.regx.test((inputValue)) && inputValue.length <= 12;
    }
}
