<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form [formGroup]="myForm">

        <div class="grid sm:grid-cols-2 gap-x-4 gap-y-2 mb-2">
            <mat-form-field class="w-full">
                <mat-label>Medio</mat-label>
                <mat-select disableOptionCentering formControlName="medio" required>
                    <mat-option>
                        <ngx-mat-select-search formControlName="filter" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let m of medios| search: myForm.get('filter').value:['nombre']" [value]="m.id">
                        {{m.nombre}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Programa / Sección</mat-label>
                <mat-select formControlName="programa" required>
                    <mat-option>Ninguno</mat-option>
                    <mat-option *ngFor="let programa of programas"
                        [value]="programa.id">{{programa.nombre}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Influencia</mat-label>
                <mat-select formControlName="influencia" required>
                    <mat-option>Ninguno</mat-option>
                    <mat-option [value]="1">Externo</mat-option>
                    <mat-option [value]="2">Interno</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Cargos</mat-label>
                <mat-select disableOptionCentering multiple formControlName="cargo" placeholder="Seleccione">
                    <mat-option>
                        <ngx-mat-select-search formControlName="filterC" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let cargo of cargos| search: myForm.get('filterC').value:['descripcion']"
                        [value]="cargo.id">{{cargo.descripcion}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="grid gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Plataformas</mat-label>
                <mat-select disableOptionCentering multiple formControlName="plataforma" placeholder="Seleccione">
                    <mat-select-trigger>
                        <mat-chip-listbox class="multi">
                            <mat-basic-chip class="chipName" *ngFor="let p of selectPlat$|async" [removable]="true"
                                (removed)="remove(p.idMedioPlataforma)">
                                <mat-icon matChipRemove>close</mat-icon>
                                <span>{{p.medio_plataforma.plataforma_clasificacion.plataforma.descripcion}} >
                                    {{p.medio_plataforma.plataforma_clasificacion.descripcion}}:
                                    {{p.medio_plataforma.valor}}</span>
                            </mat-basic-chip>
                        </mat-chip-listbox>
                    </mat-select-trigger>
                    <mat-option *ngIf="plataformas.length>4">
                        <ngx-mat-select-search formControlName="filterP" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                        *ngFor="let p of plataformas| search: myForm.get('filterP').value:['plat','descrip','val']"
                        [value]="p.idMedioPlataforma">
                        {{p.medio_plataforma.plataforma_clasificacion.plataforma.descripcion}} >
                        {{p.medio_plataforma.plataforma_clasificacion.descripcion}}: {{p.medio_plataforma.valor}}
                    </mat-option>
                </mat-select>
                <mat-hint align="end" *ngIf="noPlatform">No existen Plataformas vinculadas al Programa /
                    Sección</mat-hint>
            </mat-form-field>
            <!--mat-form-field class="px-4 w-full">
                <mat-label>Plataformas</mat-label>
                <mat-select disableOptionCentering multiple formControlName="plataforma"
                    placeholder="Seleccione">
                    <mat-option *ngIf="plataformas.length>4">
                        <ngx-mat-select-search formControlName="filterP" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let p of plataformas| search: myForm.get('filterP').value:['plat','descrip','val']"
                        [value]="p.idMedioPlataforma">
                        {{p.medio_plataforma.plataforma_clasificacion.plataforma.descripcion}} >
                        {{p.medio_plataforma.plataforma_clasificacion.descripcion}}: {{p.medio_plataforma.valor}}
                    </mat-option>
                </mat-select>
            </mat-form-field-->
            <mat-form-field class="w-full">
                <mat-label>Observación</mat-label>
                <textarea matInput placeholder="Observación" formControlName="observacion"></textarea>
            </mat-form-field>
        </div>
    </form>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" mat-dialog-close type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="myForm.invalid" [loading]="loading"
        label="Guardar"></adp-button-icon>
</div>