 <div mat-dialog-title class="!text-base">
    {{title}}
    <mat-progress-bar *ngIf="loader" mode="indeterminate"></mat-progress-bar>
 </div>

 <div mat-dialog-content class="!py-0">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="grid gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Cliente</mat-label>
                <mat-select formControlName="cliente_id" required>
                    <mat-option>
                        <ngx-mat-select-search formControlName="filter" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let c of clientes| search: myForm.get('filter').value:['nombreComercial']" [value]="c.id">
                        {{c.nombreComercial}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
    
            <mat-form-field class="w-full">
                <mat-label>Cargo</mat-label>
                <input matInput type="text" autocomplete="off" formControlName="cargo">
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
        </div>
    </form>
 </div>
 
 <div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" mat-dialog-close type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="myForm.invalid" [loading]="loading"
        label="Guardar"></adp-button-icon>
</div>


