import { Component, HostListener, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionService } from './core/services/PWA/connection.service';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth/auth.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit, OnDestroy {
  private auth = inject(AuthService);
  private translate = inject (TranslateService);
  private connection = inject(ConnectionService);

  private connectionSubscription: Subscription;

  constructor() {
    this.translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('en');

    const browserLang: string = this.translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    this.connectionSubscription = this.connection.onlineStatus$.subscribe();
  }

  ngOnDestroy(): void {
    this.connectionSubscription.unsubscribe();
  }

  @HostListener('window:storage', ['$event'])
  handleStorage(event: StorageEvent) {
    if (event.storageArea.length === 0) {
      this.auth.updateToken(null);
    }
  }
}
