import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Api } from '../../contracts/classes/api.class';

@Injectable({providedIn: 'root'})
export class ClassificationService extends Api {

  getAll() {
    return this.http.get(`${this.url}/plataformaClasificacions`).pipe(
      map((response: any) => response)
    );
  }

  create(data: any) {
    return this.http.post(`${this.url}/plataformaClasificacions`, data);
  }

  update(data: any) {
    return this.http.put(`${this.url}/plataformaClasificacions/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/plataformaClasificacions/${id}`);
  }
}
