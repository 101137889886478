import { Injectable, ErrorHandler, inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Swal2 } from '../utils/swal2.util';
import { ConnectionService } from '../services/PWA/connection.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
    private supportMail = environment.mail_support;
    private auth = inject(AuthService);
    private swal = inject(Swal2);
    private connection = inject(ConnectionService);

    handleError(error: any): void {

        if (this.connection.isConnected) {

            /** Error de Cliente */
            if (error instanceof ErrorEvent) {
                
            }

            /** Error de Servidor */
            if (error instanceof HttpErrorResponse) {
                this.isHttpErrorResponse(error)
            }

            
        } else {
            this.connection.showToastError();
        }
        console.error(error);
    }

    private isHttpErrorResponse(error: HttpErrorResponse) {
        const codes: {[key:number]: () => void} = {
            401: () => this.auth.logout(),
            403: () => this.auth.logout(),
            500: () => this.swal.httpErrorAlert(500, this.error500Template()),
            504: () => this.swal.http504ErrorAlert(this.error504Template())
        };
        
        const codeKey = Object.keys(codes).includes(error.status.toString());

        codeKey ? codes[error.status]() : null;
    }

    private error500Template(): string {
        return `
        <p>El servidor ha encontrado un error y no ha podido completar tu solicitud.</p>
        <span>Por favor, inténtelo nuevamente</span>
        `;
    }

    private error504Template() {
        return `
        <div style="width: 100%;">
            Si el problema persiste, comunícate al correo: <strong>${this.supportMail}</strong> adjuntando una captura de pantalla del error
        </div></br>
        <span style="font-size: 14px;">Gracias por su comprensión y paciencia.</span>
        `
    }
}
